@action-icon-pos-x: @global-margin-medium;
@action-icon-pos-y: @global-margin;

// @todo: refactor this.
.mtx-action-link() {
  text-decoration: none;

  &,
  &:focus {
    color: lighten(@global-muted-color, 10%);
  }

  &:hover {
    color: @global-muted-color;
  }

  &.active,
  &.uk-active {
    color: @primary_color;
  }

  &.disabled {
    color: lighten(@global-muted-color, 10%);
    cursor: default;
  }
}

// @todo: move this to separate component.
.mtx-action-item {
  @media all and (min-width: @breakpoint-medium) {
    .mtx-actions {
      visibility: hidden;
      opacity: 0;

      &.uk-open {
        visibility: inherit;
        opacity: 1;
      }
    }

    &.mtx-course-el .mtx-actions {
      visibility: visible;
      pointer-events: none;

      &.uk-open {
        pointer-events: initial;
      }
    }

    &:focus-within,
    &:hover {
      .mtx-actions {
        visibility: inherit;
        opacity: 1;
      }

      &.mtx-course-el .mtx-actions {
        pointer-events: initial;
      }
    }
  }

  .uk-touch & {
    .mtx-actions {
      visibility: inherit;
      opacity: 1;
    }

    &.mtx-course-el .mtx-actions {
      pointer-events: initial;
    }
  }
}

.mtx-actions {
  -webkit-tap-highlight-color: transparent;

  a.mtx-handler {
    display: block;
  }

  .mtx-course-el &,
  .video-item &,
  .archive-item &,
  .h5p-item &,
  .mtx-slides-set &,
  .mtx-api-image-archive-wrapper & {
    position: absolute;
    top: @action-icon-pos-y;
    z-index: 100;
    transition: @global-hover-transition;
    line-height: 1;

    &-wrapper {
      position: absolute;
      top: 0;
      width: @md-icon-size + @action-icon-pos-x;
      height: @md-icon-size + @action-icon-pos-y;
      z-index: 99;

      &.dropdown-active {
        z-index: 100;
      }
    }

    [dir=ltr] & {
      right: @action-icon-pos-x;

      &-wrapper {
        right: 0;
      }
    }

    [dir=rtl] & {
      left: @action-icon-pos-x;

      &-wrapper {
        left: 0;
      }
    }
  }
}

a.mtx-flag-link {
  .mtx-action-link;

  .mtx-flag-wrapper & {
    @media all and (max-width: @breakpoint-medium-max) {
      font-size: @global-font-size-small;
    }
  }
}
