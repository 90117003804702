@chosen-item-height: unit(26 / 14, em);

.chosen {
  select& {
    height: @form-height !important;
    font-family: inherit;
    font-size: @form-font-size;

    option {
      display: none;
    }
  }
}

.chosen-container {
  position: relative;
  display: block !important;
  max-width: 100%;
  min-width: 100%;
  width: auto !important;
  vertical-align: middle;
  font-family: @form-font-family;
  zoom: 1;
  *display: inline;
  user-select: none;

  .chosen-drop {
    display: none;
    position: absolute;
    top: 100%;
    left: -9999px;
    z-index: @dropdown-z-index;
    width: 100%;
    box-shadow: @dropdown-box-shadow;
    border-radius: 0 0 @inputs-border-radius @inputs-border-radius;
    background: @dropdown-background;
    color: @dropdown-color;
    background-clip: padding-box;
    overflow: hidden;
  }

  &.chosen-with-drop .chosen-drop {
    display: block;
    left: 0;
  }

  a {
    cursor: pointer;
  }

  &.error {
    .chosen-choices,
    .chosen-single {
      border-width: @form-border-width;
      border-color: @form-danger-border;
    }
  }

  &.chosen-disabled .chosen-drop {
    display: none !important;
  }
}

/* @end */
/* @group Single Chosen */
.chosen-container-single {
  .chosen-single {
    position: relative;
    display: block;
    overflow: hidden;
    padding: @form-padding;
    padding-inline-end: @form-icon-width;
    height: @form-height;
    border: @form-border-width solid @form-border;
    background-color: @form-background;
    color: @form-color;
    text-decoration: none;
    white-space: nowrap;
    line-height: @global-line-height;
    border-radius: @inputs-border-radius;

    &.chosen-single-with-deselect {
      padding-inline-end: (@md-icon-size * 2) + @form-padding-x + (@global-margin-small * 2);
    }

    span {
      .text-ellipsis();
    }

    .chosen-default {
      color: @global-placeholder-color;
    }

    abbr,
    div {
      position: absolute;
      top: 0;
      text-align: center;
      color: @global-placeholder-color;
    }

    abbr {
      outline: 0 none;
      margin-inline-end: @global-margin-small;

      &:not(.custom) {
        display: none;
      }

      &:before {
        .material-icon(1.125rem);
        content: '\e5cd'; // md-close
        width: @md-icon-size;
        line-height: @form-height;
      }

      &:hover,
      &:focus {
        opacity: .7
      }
    }

    div b:before {
      .material-icon();
      content: '\e5cf'; // md-expand_more
      width: @md-icon-size;
      line-height: @form-height;
    }

    &:focus,
    &:active {
      border-color: @form-focus-border;
    }

    [dir=rtl] [dir=ltr] &,
    [dir=ltr] & {
      abbr {
        left: auto;
        right: @md-icon-size + @form-padding-x;
      }

      div {
        right: @form-padding-x;
        left: auto;
      }
    }

    [dir=rtl] & {
      abbr {
        left: @md-icon-size + @form-padding-x;
        right: auto;
      }

      div {
        left: @form-padding-x;
        right: auto;
      }
    }
  }

  .chosen-default {
    color: @global-placeholder-color;
  }

  .chosen-search {
    position: relative;
    display: block;
    z-index: @dropdown-z-index;
    margin: @form-padding-x;
    white-space: nowrap;

    // @todo: Find a way to handle this properly.
    .enroll-section &,
    .commerce-settings & {
      display: none;
    }

    input[type="text"] {
      padding: @form-padding;
      width: 100%;
      height: @form-height;
      outline: 0 none;
      border: @form-border-width solid @form-border;
      font-size: @global-font-size-small; // @Todo: Dropdown
      border-radius: @inputs-border-radius;

      &:focus {
        border-color: @form-focus-border;
      }
    }

    .input-search-icon;
  }

  &.chosen-container-single-nosearch .chosen-search {
    position: absolute;
    display: none;
    left: -9999px;
  }
}

/* @end */
/* @group Results */
.chosen-container .chosen-results {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 240px;
  -webkit-overflow-scrolling: touch;
  font-size: @dropdown-font-size;
  .reset-list();

  li {
    display: none;
    padding: @global-margin @global-margin-large; // @Todo: Dropdown
    line-height: 1.3; // @Todo: Dropdown
    -webkit-touch-callout: none;
    word-break: break-word;

    &.active-result {
      display: list-item;
      cursor: pointer;
    }

    &.disabled-result {
      display: list-item;
      color: @global-muted-color;
      cursor: default;
    }

    &.no-results {
      display: list-item;
    }

    &.group-result {
      display: list-item;
      font-weight: bold;
      cursor: default;
    }

    &.group-option {
      padding-left: 15px;
    }

    em {
      font-style: normal;
      text-decoration: underline;
    }
  }
}

/* @end */
/* @group Multi Chosen */
.chosen-container-multi {
  @padding-x: @global-margin;
  @padding-y: @global-margin-small;
  @close-size: 1.25rem;
  @choices-margin: @global-margin-small;

  .chosen-choices {
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0 @choices-margin 0 0;
    border: @form-border-width solid @form-border;
    border-radius: @inputs-border-radius;
    background-color: @form-background;
    cursor: text;
    outline: 0 none;

    li {
      vertical-align: middle;

      &.search-field {
        white-space: nowrap;

        input[type="text"] {
          padding: @form-padding;
          height: unit(@form-height - (@form-border-width / 16) * 2, rem);
          outline: 0 none;
          border: 0 none;
          background: none;
          color: @form-color;
          font-size: @form-font-size;
          font-family: @form-font-family;
          line-height: @global-line-height;
          width: 100% !important;
          border-radius: @inputs-border-radius;

          &:focus-visible {
            width: calc(100% - @choices-margin) !important;
            margin: @focus-state-box-shadow-size 0 @focus-state-box-shadow-size @choices-margin;
          }

          &::-ms-clear {
            display: none;
          }

          [dir=rtl] & {
            padding-right: @global-margin-small; // @form-padding-y - @choices-margin
          }
        }

        .default {
          color: @global-placeholder-color !important;
        }
      }

      &.search-choice {
        display: inline-block;
        position: relative;
        margin: (@choices-margin / 2) 0 (@choices-margin / 2) @choices-margin;
        border-radius: @inputs-border-radius;
        background-clip: padding-box;
        line-height: 1.3; // @Todo: Dropdown
        cursor: default;
        .break-word();

        .search-choice-close {
          position: absolute;
          top: @global-margin-small;
          display: block;
          font-size: @global-font-size-xsmall;

          &:after {
            .material-icon(@close-size);
            content: '\e5cd'; // md-close
          }
        }

        &.search-choice-disabled {
          padding: @padding-y @padding-x !important;
          background-color: #e4e4e4;
          color: #666;
        }

        [dir=ltr] &,
        [dir=rtl] [dir=ltr] & {
          padding: @padding-y (@close-size + @padding-x * 2) @padding-y @padding-x;

          .search-choice-close {
            right: @padding-x;
          }
        }

        [dir=rtl] & {
          padding: @padding-y @padding-x @padding-y (@close-size + @padding-x * 2);

          .search-choice-close {
            left: @padding-x;
          }
        }
      }
    }
  }

  .search-choice-close {
    opacity: .5;
    transition: opacity .15s ease-in-out;
  }

  &:not(.chosen-disabled) {
    .search-choice-close:hover {
      opacity: 1;
    }
  }

  &.chosen-with-drop .chosen-choices {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .chosen-results {
    margin: 0;
    padding: 0;
  }

  &.chosen-disabled {
    &.mtx-api-autocomplete-select {
      li.search-field {
        display: none;
      }

      .search-choice-close,
      .chosen-drop {
        display: none !important;
      }

      .chosen-choices li.search-choice {
        padding: @padding-y @padding-x;
      }

      .search-choice {
        margin-top: .313rem;
        margin-bottom: .313rem;
      }
    }
  }
}

/* @end */
/* @group Active  */
.chosen-container-active {
  .chosen-single,
  .chosen-choices {
    border: @form-border-width solid @form-focus-border;
    transition: @form-border-transition;
  }

  &.chosen-with-drop .chosen-single {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

    div b:before {
      content: '\e5ce'; // md-expand_less
    }
  }

  .chosen-choices {
    li.search-field input[type="text"] {
      color: @form-color !important;
    }
  }
}

/* @end */
/* @group Disabled Support */
.chosen-disabled {
  opacity: .5 !important;
  cursor: default;

  .chosen-single {
    cursor: default;
    border-color: @form-border !important;

    &:focus {
      outline: none;
    }
  }

  .chosen-choices {
    border: @form-border-width solid @form-border !important;
    border-radius: @inputs-border-radius !important;

    .search-choice .search-choice-close {
      cursor: default;
    }
  }
}

/* @end */
