@page-header-padding-x: @global-margin-large;
@page-header-padding-x-large: @global-margin-medium * 2;
@page-header-padding-y: @global-margin-medium;
@page-header-box-shadow: 0 0 4px fade(#212222, 8%);

.page-header {
  padding: @page-header-padding-y @page-header-padding-x;
  box-shadow: @page-header-box-shadow;

  & > div {
    min-height: 3rem; // @button-height
  }

  &-wrapper {
    z-index: @page-sidebar-z-index + 7;

    & > .trial-info {
      background-color: @_grey_lighten_color;
      padding: @global-margin-small @global-margin-large;

      .trial-info-inner {
        justify-content: center;
      }
    }

    body.nei-container-overlay-mode & {
      z-index: @nei-container-z-index + 1;
    }
  }

  .page-logo {
    max-width: 25%;
    height: 3rem;

    a {
      display: flex;
      align-items: center;
      height: 100%;
      max-width: 300px;
    }

    img {
      display: block;
      max-height: 100%;

      &[src*=svg] {
        height: 100%;
      }
    }
  }

  .page-title {
    @line-height: 2;

    max-width: 50%;
    font-size: @global-font-size;
    line-height: @line-height;
    word-break: break-word;
    .text-ellipsis-box(2, @line-height);
    margin: 0;
    margin-inline-start: @global-margin-large;
    padding: 0;
    padding-inline-start: @global-margin-large;
    border-inline-start: 1px solid @_grey_light_color;
  }

  .user-block {
    margin-inline-start: auto;

    & > * {
      margin-inline-start: @global-margin;
    }

    & > .trial-info {
      display: none;
    }

    .activity-deadline.overdue {
      color: #ff0000;
    }

    .mtx-actions {
      &[data-uk-dropdown] > a.mtx-handler > i.md-icon {
        color: inherit;
      }
    }

    .mtx-training-progress {
      @progress-height: .75rem;
      @progres-border-radius: calc(@progress-height / @progress-border-radius-ratio);
      @dropdown-padding: @global-margin-medium * 2;

      .progress-status-icon {
        .circle(@button-height);

        svg {
          width: @button-height;
          height: @button-height;
        }
      }

      .uk-dropdown-toggler {
        border-radius: @button-border-radius;
      }

      .mtx-circular-progress {
        .position-cover();

        .md-icon {
          .position-center();
          display: block !important;
          font-weight: initial;

          &:after {
            content: '\ea23'; // md-emoji_events
          }
        }

        &.lp-module .md-icon:after {
          content: '\f0be'; // md-check_circle
        }
      }

      [data-uk-dropdown] {
        &.disabled > .uk-dropdown-toggler {
          pointer-events: none;
          cursor: wait;
        }
      }

      .uk-dropdown {
        padding: @dropdown-padding;
        width: calc(300px - @page-quick-link-size);
      }

      .mtx-custom-scrollbar {
        max-height: 380px;
        margin-inline-end: -(@dropdown-padding / 2);
        margin-inline-start: 0;
      }

      .mtx-progress-bar {
        height: @progress-height;
        border-radius: @progres-border-radius;

        &-inner {
          border-radius: @progres-border-radius;
        }
      }

      .dropdown-header,
      .dropdown-details {
        font-size: @global-font-size;
      }

      .dropdown-details {
        word-break: break-word;

        li + li {
          margin-top: @global-margin-small;
        }

        .icon {
          .circle(2.5rem);
          min-width: 2.5rem;
          position: relative;
          background-color: @_grey_lighten_color;
        }

        .icon .md-icon {
          .position-center();
          font-size: 1.25rem;
        }
      }
    }

    .learning-path-overview-page,
    .learn-mode-page-view & {
      .mtx-actions > a {
        display: inline-block;
        width: @button-height;
        height: @button-height;
        text-align: center;
        border-radius: @button-border-radius;

        .md-icon {
          line-height: @button-height;
        }
      }

      & > * {
        margin-inline-start: @global-margin;
      }
    }

    .learning-path-overview-page & {
      .mtx-actions .mtx-handler {
        display: inline-block;
        width: @button-height;
        text-align: center;
        line-height: @button-height;
        border-radius: @button-border-radius;
        transition: all .15s ease-in-out;
      }
    }
  }

  .user-info {
    color: inherit;

    .mtx-avatar-icon,
    .user-avatar img {
      min-width: 2.5rem;
      width: 2.5rem !important;
      height: 2.5rem !important;
    }

    .user-name {
      .break-word();
      font-size: @global-font-size-small;
      margin-inline-end: @global-margin-small;
    }
  }

  @media all and (min-width: @breakpoint-small) {
    .user-block > * {
      margin-inline-start: @global-margin-medium;
    }

    .user-block .mtx-training-progress {
      .uk-dropdown {
        width: 360px;
      }
    }
  }

  @media all and (max-width: @breakpoint-small-max) {
    .user-block .mtx-training-progress {
      .uk-dropdown {
        margin-inline-start: @page-quick-link-size;
      }
    }
  }

  @media all and (min-width: @breakpoint-medium) {
    .user-block > *,
    .authorization-links .buttons > a + a {
      margin-inline-start: @global-margin-large;
    }

    .authorization-links .mtx-actions {
      display: none;
    }

    .user-block .mtx-training-progress {
      & + .mtx-actions {
        margin-inline-start: @global-margin-medium * 2;
      }

      .progress-status-icon {
        margin-inline-end: @global-margin-medium;
      }
    }
  }

  @media all and (min-width: @breakpoint-large) {
    padding: @page-header-padding-y @page-header-padding-x-large;

    .user-block > *,
    .authorization-links .buttons > a + a {
      margin-inline-start: @global-margin-medium * 2;
    }

    .user-block > .trial-info {
      display: block;
    }

    &-wrapper > .trial-info {
      display: none;
    }
  }

  @media all and (min-width: @breakpoint-xlarge) {
    .page-logo {
      max-width: 20%;
    }
  }

  @media all and (max-width: @breakpoint-medium-max) {
    .user-block > .user-info-wrapper {
      margin-inline-start: @global-margin-large;
    }
  }

  @media all and (max-width: @breakpoint-small-max) {
    .page-logo {
      max-width: 35%;
    }

    #mtx-search-header-search-form {
      .form-item-s input {
        min-width: unset;
      }

      a.search-button {
        border-radius: @button-border-radius;
      }
    }

    .mtx-notifications {
      .mtx-handler {
        border-radius: @button-border-radius;
      }
    }

    .authorization-links {
      .buttons {
        display: none;
      }

      .mtx-actions {
        .mtx-handler {
          display: inline-block;
          width: @button-height;
          text-align: center;
          line-height: @button-height;
          border-radius: @button-border-radius;
        }
      }
    }

    &.search-active {
      .page-logo {
        opacity: 0;
      }

      .user-block {
        position: absolute;
        width: 100%;
        justify-content: flex-end;
      }

      #mtx-search-header-search-form {
        width: 100%;
        margin: 0;
        .clearfix();

        .form-item-s input {
          width: 100%;
        }

        [dir=ltr] & {
          .form-item-s input {
            right: 0;
          }

          a.search-button {
            float: right;
          }
        }

        [dir=rtl] & {
          .form-item-s input {
            left: 0;
          }

          a.search-button {
            float: left;
          }
        }
      }
    }

    .learn-mode-page-view & {
      .page-title {
        max-width: 40%;
      }
    }
  }

  @media all and (max-width: @breakpoint-mini-max) {
    .learn-mode-page-view & {
      .page-title {
        display: none !important;
      }
    }
  }
}
