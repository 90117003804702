.mtx-salesforce-info {
  .sf-logo {
    width: 8rem + @global-margin-large;
    height: 5.625rem + @global-margin-large;
  }

  &.configured {
    background-color: @_grey_lighten_color;
    border-radius: @global-border-radius;
    display: table;
    width: 100%;
    padding: @global-margin;

    .sf-logo,
    .sf-info,
    .sf-actions {
      display: table-cell;
      vertical-align: middle;
      padding: @global-margin;
    }

    .sf-logo {
      path.sf-fill {
        fill: #00a1e0;
      }
    }

    .sf-info {
      font-size: @global-font-size-small;
    }

    .sf-configs-list {
      .reset-list;

      li {
        display: inline-block;
        padding: 0 .625em;
        font-size: @global-font-size-small;
        line-height: 2;
        background-color: @_grey_light_color;
        color: @_grey_dark_color;
        border-radius: @global-border-radius;
      }
    }
  }

  &.not-configured {
    text-align: center;
    padding: @global-margin-large 0;

    .sf-logo {
      margin: 0 auto;

      path.sf-fill {
        fill: @_grey_light_color;
      }
    }

    .sf-info {
      font-weight: @global-font-weight-semibold;
    }

    .sf-actions {
      margin: @global-margin-large auto;
    }
  }
}


// Salesforce configuration form
#salesforce-wizard {
  #salesforce-objects .form-item {
    margin-top: 0;
  }
}
