@mtx-search-field-expanded-width: 13.75rem;

#mtx-search-header-search-form {
  @icon-size: 2.5rem; // 40px
  @icon-large-size: 3rem; // @button-height
  @input-padding: @form-padding-x + @global-margin-small; // @form-icon-width

  z-index: 1;

  .form-item-s {
    margin: 0;
    position: relative;

    input {
      position: absolute;
      width: 0;
      min-width: 0;
      top: -((@form-height - @icon-size) / 2);
      padding-left: 0;
      padding-right: 0;
      border: 0 none;
      border-radius: 0;

      .placeholder({
        color: inherit;
      });

      &.active {
        min-width: @mtx-search-field-expanded-width;
        width: @mtx-search-field-expanded-width;
        transition: all .2s ease-out;

        [dir=ltr] & {
          right: -(@input-padding / 2);
          padding-left: @form-padding-x;
          padding-right: (@icon-size + @input-padding);
        }

        [dir=rtl] & {
          left: -(@input-padding / 2);
          padding-right: @form-padding-x;
          padding-left: (@icon-size + @input-padding);
        }
      }
    }
  }

  a.search-button {
    text-align: center;
    display: inline-block;
    width: @icon-size;
    font-size: 0;
    line-height: @icon-size;
    vertical-align: middle;
    transition: color .15s ease-in-out;
    .button-border-radius(@icon-size);
  }

  @media all and (max-width: @breakpoint-medium-max) {
    .form-item-s input {
      top: -((@form-height - @icon-large-size) / 2);

      &.active {
        [dir=ltr] & {
          right: 0;
          padding-right: @icon-large-size;
        }

        [dir=rtl] {
          left: 0;
          padding-left: @icon-large-size;
        }
      }
    }

    a.search-button {
      width: @icon-large-size;
      line-height: @icon-large-size;
      .button-border-radius(@icon-large-size);
    }
  }
}

// Former #mtx-search-page-search-form
.mtx-search-form {
  .form-item-s {
    input {
      &:extend(.page-title);
      font-weight: @global-font-weight-bold;
      border-width: 0 0 1px 0;
      border-radius: 0;
      height: auto;
      margin-bottom: @global-margin;
      padding: 0 0 @global-margin 0;

      // Implement placeholder styles
      .placeholder({
        font-size: inherit;
      });
    }
  }
}

.mtx-search-results {
  .mtx-list-item {
    .meta .md-icon {
      font-size: 1rem;
      line-height: unit(@list-meta-font-size * @global-line-height, em);
      vertical-align: bottom;

      [dir=ltr] & {
        margin-right: @global-margin-small;
      }

      [dir=rtl] & {
        margin-left: @global-margin-small;
      }
    }
  }

  .mtx-search-keywords {
    display: flex;
    flex-wrap: wrap;

    &-item {
      border: 1px solid @_grey_dark_color;
      font-size: @global-font-size-small;
      padding: 0 @global-margin-small;
      margin-top: @global-margin-small;
      line-height: calc(2em - 2px); // 28px

      [dir=ltr] & {
        margin-right: @global-margin-small;
      }

      [dir=rtl] & {
        margin-left: @global-margin-small;
      }
    }
  }

  .pager {
    margin-top: @global-margin-large;
  }
}

.mtx-search-resource {
  margin-top: @global-margin;

  .title {
    line-height: @list-icon-size-small;
    font-weight: @global-font-weight-normal;
    .text-ellipsis();
  }


  .icon.small {
    [dir=ltr] & {
      margin-right: @global-margin;

      & + .item-content {
        margin-left: @list-icon-size-small + @global-margin;
      }
    }

    [dir=rtl] & {
      margin-left: @global-margin;

      & + .item-content {
        margin-right: @list-icon-size-small + @global-margin;
      }
    }
  }
}
