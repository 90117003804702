.view-organization-users {
  .organization-details {
    & > * {
      display: inline-block;
      vertical-align: middle;

      .md-icon {
        color: @_grey_dark_color;
      }

      [dir=ltr] & {
        margin-right: @global-margin-medium * 2;

        .md-icon {
          margin-right: @global-margin-mini;
        }
      }

      [dir=rtl] & {
        margin-left: @global-margin-medium * 2;

        .md-icon {
          margin-left: @global-margin-mini;
        }
      }

      &:last-child {
        margin-left: 0;
        margin-right: 0;
      }
    }

    & + .organization-actions {
      margin-top: @global-margin-medium * 2;
    }
  }

  .organization-actions {
    .mtx-button-group {
      .uk-button + .uk-dropdown {
        min-width: 100%;
      }
    }
  }

  .organization-credits-spent {
    .md-icon {
      color: #ff8c09;
    }
  }

  .views-widget-filter-field_job_function_tid,
  .views-widget-filter-field_user_organization_tid,
  .views-widget-filter-field_user_country_value {
    display: none;
  }

  .views-field {
    &-name {
      min-width: 15rem;
      max-width: 18rem;
      word-break: break-word;

      .avatar {
        min-width: @mtx-users-avatar-size;

        &,
        img {
          .circle(@mtx-users-avatar-size);
        }
      }

      .text > div + div {
        margin-top: @global-margin-mini;
      }
    }

    &-created,
    &-field-user-organization {
      min-width: 10em;
    }

    &-field-user-organization {
      [data-show-more] {
        max-height: @table-font-size * @table-line-height * 3;
      }
    }
  }

  .item-list .pager {
    margin-top: 2rem;
  }
}
