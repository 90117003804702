@palette-preview-size: 1.5rem;
@palette-preview-border-radius: calc(@dropdown-border-radius - 1px);
@palette-color-field-width: 8.75rem;

.mtx-spectrum {
  .form-item {
    margin-bottom: @global-margin-large;
  }

  // Input.
  .form-type-textfield {
    .clearfix;
    position: relative;

    label {
      display: inline-block;

      &,
      & ~ .description {
        width: 54%;
      }
    }
  }

  .color-field {
    display: block !important;
    width: @palette-color-field-width;
    text-transform: uppercase;
    padding-inline-end: @palette-preview-size + @form-padding-x;
  }

  .sp-replacer {
    width: @palette-preview-size;
    height: @palette-preview-size;
    position: absolute;
    top: @form-padding-y;
    border: none;
    padding: 0;
    background: none;

    .sp-preview {
      margin: 0;
      width: 100%;
      height: 100%;
      float: none;
      border: none;
      background: none;

      &-inner {
        background: none;
        border: 1px solid @form-border;
        border-radius: calc(unit(@palette-preview-size * 16, px) / @inputs-border-radius-ratio);
      }
    }

    .sp-dd {
      display: none;
    }

    [dir=ltr] &,
    [dir=rtl] [dir=ltr] & {
      left: auto;
      right: @form-padding-x;
    }

    [dir=rtl] & {
      left: @form-padding-x;
      right: auto;
    }
  }

  // Dropdown.
  .sp-container {
    .clearfix;
    border: 0 none;
    border-radius: @dropdown-border-radius;
    padding: @global-margin-small @global-margin-small 0;
    background-color: @dropdown-background;
    box-shadow: @dropdown-box-shadow;
  }

  .sp-picker-container {
    margin: 0 0 @global-margin-small / 2;
    padding: 0;
  }

  // Palette.
  .sp-palette {
    display: none;
  }

  .sp-thumb {
    display: none;
  }

  // Spectrum.
  .sp {
    &-top {
      margin-bottom: 0;
    }

    &-fill {
      padding-top: 85%;
    }

    &-color {
      .sp-sat,
      .sp-val {
        border-radius: @palette-preview-border-radius;
      }

      [dir=rtl] & {
        right: 0;
        left: 20%;
      }
    }

    &-hue {
      [dir=ltr] &,
      [dir=rtl] [dir=ltr] & {
        left: 84% !important;
        right: 0 !important;
      }

      [dir=rtl] & {
        right: 84% !important;
        left: 0 !important;
      }
    }

    &-color,
    &-hue {
      border: 0 none;
      border-radius: @palette-preview-border-radius;
    }

    &-dragger {
      @size: 8px;

      .square(@size);
      .button-border-radius(@size / 16);
      border: 1px solid @form-border;
      background-color: @_white_color;
    }

    &-slider {
      @height: 6px;

      left: -(@height / 2);
      right: -(@height / 2);
      height: @height;
      border: 1px solid @form-border;
      .button-border-radius(@height / 16);
      opacity: 1;
    }
  }

  @media all and (max-width: @breakpoint-mini-max) {
    .form-type-textfield {
      label {
        &,
        & ~ .description {
          width: 45%;
        }
      }
    }
  }
}

.mtx-spectrum-reset {
 .color-reset {
    position: absolute;
    top: 0;
    line-height: @form-height;
    width: @form-height;
    text-align: center;
    opacity: 1;
    transition: all .15s ease-in-out;

    &.hidden {
      display: none;
      opacity: 0;
    }
  }

  .color-field {
    margin-inline-end: @form-height;
  }

  [dir=ltr] &,
  [dir=rtl] [dir=ltr] & {
    .color-reset {
      right: 0;
    }

    .sp-replacer {
      right: @form-height + @form-padding-y;
    }
  }

  [dir=rtl] & {
    .color-reset {
      left: 0;
    }

    .sp-replacer {
      left: @form-height + @form-padding-y;
    }
  }
}
