// @todo: refactor this file.
@drive-file-thumb-size: @list-icon-size-small;

.mtx-drive {
  .mtx-filter-and-search-wrapper {
    margin-top: 0;

    .search {
      .input-search-icon();

      input {
        width: 100%;
      }

      @media all and (max-width: @breakpoint-small-max) {
        width: calc(100% - @form-height - @global-margin);
      }
    }
  }

  .api-dialog & .filters {
    margin: 0;
  }

  .mtx-api-filter {
    display: none;
  }

  @media all and (max-width: @breakpoint-mini-max) {
    #mtx-api-filter {
      [dir=ltr] & {
        margin-left: @global-margin-small;
      }

      [dir=rtl] & {
        margin-right: @global-margin-small;
      }
    }
  }
}

.mtx-archive-actions {
  .action-paste {
    &-cancel .uk-button {
      padding: 0 !important;
      width: @md-icon-size + (@button-padding-horizontal / 2);
    }

    [dir=ltr] & {
      border-radius: @button-border-radius 0 0 @button-border-radius;
      padding-right: @global-margin-small;

      &-cancel .uk-button {
        border-radius: 0 @button-border-radius @button-border-radius 0;

        &:after {
          right: @button-padding-horizontal / 4;
        }
      }
    }

    [dir=rtl] & {
      border-radius: 0 @button-border-radius @button-border-radius 0;
      padding-left: @global-margin-small;

      &-cancel .uk-button {
        border-radius: @button-border-radius 0 0 @button-border-radius;

        &:after {
          left: @button-padding-horizontal / 4;
        }
      }
    }
  }
}

.archive-wrapper {
  .api-item {
    cursor: pointer;
    border-radius: @thumb-border-radius;

    &.is-not-interactive {
      cursor: default;
    }

    .status {
      top: @global-margin;
      left: @global-margin-medium;
      line-height: 1;
      z-index: 1;
      position: absolute;
      color: @_grey_color;

      .md-icon + .md-icon {
        margin: 0 0 0 @global-margin-small;
      }

      [dir=rtl] & {
        left: auto;
        right: @global-margin-medium;

        .md-icon + .md-icon {
          margin: 0 @global-margin-small 0 0;
        }
      }
    }

    .title {
      h2 {
        font-size: @global-font-size-small;
        font-weight: @global-font-weight-normal;
        margin: 0;
        .text-ellipsis-box(3);
      }
    }

    .file-version {
      display: none;
      position: absolute;
      bottom: @global-margin;
      font-size: @global-font-size-small;
      line-height: 1.3;
      color: @_white_color;
      z-index: 1;

      [dir=ltr] & {
        left: @global-margin-medium;
      }

      [dir=rtl] & {
        right: @global-margin-medium;
      }
    }
  }

  .pager {
    text-align: center;
    line-height: 2;

    .fa {
      color: @global-muted-color !important;
    }
  }

  // List view
  &.list {
    @table-padding-h: @global-margin;
    @table-padding-v: @global-margin;

    .archive-item {
      position: relative;
    }

    .archive {
      border-radius: @thumb-border-radius;
      width: 100%;
      padding: 0;
      margin: @global-margin-mini;

      .mtx-actions-wrapper {
        top: 50%;
        transform: translateY(-50%);
      }

      .mtx-actions {
        top: @action-icon-pos-y / 2;

        a .md-icon {
          color: @_grey_dark_color;

          &:hover {
            color: @_grey_darken_color;
          }
        }

        [dir=ltr] & {
          right: @action-icon-pos-x / 2;
        }

        [dir=rtl] & {
          left: @action-icon-pos-x / 2;
        }
      }
    }

    .archive.selected {
      background-color: @_grey_lighten_color;

      .api-item .title a {
        &:active,
        &:focus,
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .api-item {
      margin-bottom: 0; // rewrites styles related to .api-dialog class
      padding: @table-padding-v @table-padding-h;

      .thumb {
        border: none;
        width: @drive-file-thumb-size;
        padding-top: @drive-file-thumb-size;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        img {
          padding: 0;
        }

        .format {
          display: none;
        }
      }

      .title {
        margin-top: 0;
        min-height: @drive-file-thumb-size;

        h2 {
          .text-ellipsis-box(3);
        }
      }

      .name.has-parent {
        .parent-path {
          .text-ellipsis-box(1);
          word-break: break-all;
        }
      }

      .status {
        display: none;
      }
    }

    .mtx-expiration-text {
      display: none !important;
    }

    .watermark-list {
      position: absolute;
      color: @_grey_color;
      margin: 0 @global-margin-small;
    }

    &.archive-wrapper {
      .list-view-header-wrapper {
        display: none;
      }
    }

    // Table styles.
    .list-view-header-wrapper,
    .files {
      margin: 0 -@table-padding-h;
    }

    .list-view-header-wrapper {
      display: block;
      padding: @table-padding-v @table-padding-h 0;

      .list-view-header {
        font-size: @global-font-size-small; // the same as list items font-size - @api-item-font-size
        font-weight: @table-th-font-weight;
      }
    }

    .list-view-header-wrapper,
    & {
      .name {
        width: 30%;
      }

      .author,
      .updated {
        width: 20%;
      }

      .size,
      .version {
        width: 15%;
      }
    }

    .list-view-header,
    .api-item .title {
      display: table;
      width: 100%;
      white-space: normal; // rewrites styles related to .api-dialog class
      word-break: break-word;

      & > * {
        display: table-cell;
        padding: 0 @global-margin-large;
        vertical-align: middle;

        @media all and (max-width: @breakpoint-small-max) {
          padding: 0 @global-margin;
        }
      }

      [dir=ltr] & {
        text-align: left;
        padding-left: @drive-file-thumb-size;
        padding-right: 1em;
      }

      [dir=rtl] & {
        text-align: right;
        padding-right: @drive-file-thumb-size;
        padding-left: 1em;
      }
    }

    @media all and (max-width: @breakpoint-small-max) {
      .list-view-header-wrapper {
        padding: @table-padding-v @table-padding-v 0;
      }

      .api-item {
        padding: @table-padding-v;
      }
    }
  }

  // Grid view
  &.grid {
    .archive.selected {
      .api-item {
        .thumb {
          box-shadow: 0 0 0 4px @secondary_color;
        }
      }
    }

    .archive-item {
      position: relative;

      &:hover {
        .api-item .thumb-image-hover {
          visibility: visible;
          opacity: 1;
        }

        .status {
          color: @_white_color;
        }

        .file-version {
          display: block;
        }
      }

      &:focus-within {
        .thumb-image-hover {
          visibility: visible;
          opacity: 1;
        }

        .file-version {
          display: block;
        }
      }
    }

    .api-item {
      .thumb-image-hover {
        .position-cover();
        background-color: @overlay-color-darken;
        visibility: hidden;
        opacity: 0;
        transition: @global-hover-transition;
      }

      &.file,
      &.image {
        .thumb {
          border: none;
        }
      }

      &.image .thumb img {
        padding: 0;
      }

      &.folder {
        text-decoration: none;

        .title {
          text-decoration: @base-link-text-decoration;
        }

        &:hover,
        &:focus {
          .title {
            text-decoration: @base-link-hover-text-decoration;
          }
        }

        .folder-icon {
          font-size: 2rem;
        }

        [dir=rtl] & + .mtx-actions-wrapper {
          left: 0;
        }
      }

      .title {
        .name {
          .text-ellipsis-box(2);
        }

        & > *:not(.name),
        .parent-path {
          display: none;
        }
      }

      .watermark-list {
        display: none;
      }
    }

    .mtx-expiration-thumb {
      .mtx-copyright-sign {
        display: none;
      }
    }
  }
}
