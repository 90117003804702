@import "../../colors/variables";

//**
// UIkit

//
// Component: Variables
//
// ========================================================================

// Global variables
// ========================================================================

//
// Colors
//

@global-page-background-color: @page_content_background_color;
@global-base-color: @page_background_color;
@global-text-color: @text_color;
@global-link-color: @link_color;
@global-link-hover-color: darken(@global-link-color, 10%);
@global-breadcrumb-color: @primary_color;
@global-breadcrumblinks-color: @breadcrumbs_links_color;

@global-border-color: @_grey_color;
@global-focus-border-color: @_grey_dark_color;
@global-fieldset-color: @_grey_light_color;

// Status colors
@global-warning-color: #ffc600;
@global-success-color: @secondary_color;

// Overlay colors (with transparency)
@overlay-color-light: fade(@_white_color, 12%);
@overlay-color-lighten: fade(@_white_color, 24%);
@overlay-color-dark: fade(@_black_color, 12%);
@overlay-color-darken: fade(@_black_color, 24%);

//
// Text
//

@global-color: @global-text-color;
@global-muted-color: @_grey_dark_color;
@global-placeholder-color: @_grey_dark_color;

@global-contrast-color: #fff;

@global-font-size: 1rem;
@global-font-size-small: .875rem;
@global-font-size-xsmall: .75rem;
@global-font-size-xxsmall: .625rem;
@global-font-size-large: 1.125rem;
@global-font-size-xlarge: 1.375rem;

@global-font-weight-light: 300;
@global-font-weight-normal: 400;
@global-font-weight-semibold: 600;
@global-font-weight-bold: 700;
@global-font-weight-extrabold: 800;

@global-font-family: 'Montserrat', 'Helvetica Neue', Arial, Helvetica, sans-serif;

@global-line-height: 1.5;

//
// Backgrounds & Borders
//

@global-background: @global-base-color;
@global-border: @global-border-color;

@global-primary-background: #0072cd;
@global-success-background: #8cc14c;
@global-warning-background: #ffc71a;
@global-danger-background: #da314b;

//
// Breakpoints
//

@breakpoint-small: 480px;
@breakpoint-medium: 768px;
@breakpoint-large: 960px;
@breakpoint-xlarge: 1220px;
@breakpoint-xxlarge: 1367px;

@breakpoint-mini-max: (@breakpoint-small - 1);
@breakpoint-small-max: (@breakpoint-medium - 1);
@breakpoint-medium-max: (@breakpoint-large - 1);
@breakpoint-large-max: (@breakpoint-xlarge - 1);
@breakpoint-xlarge-max: (@breakpoint-xxlarge - 1);

// Radius
@global-border-radius: 4px;
@global-border-radius-medium: 5px;
@global-border-radius-large: 12px;
@global-border-radius-xlarge: 16px;
@global-border-radius-circle: 500rem;

// Configurable border radius.
@button-border-radius: var(--button-border-radius); // MAX: 24
@button-border-radius-ratio: var(--button-border-radius-ratio); // HEIGHT: 48
@button-border-radius-mini: var(--button-border-radius-mini);
@button-border-radius-small: var(--button-border-radius-small);
@button-border-radius-large: var(--button-border-radius-large);
@inputs-border-radius: var(--inputs-border-radius); // MAX: 24
@inputs-border-radius-ratio: var(--inputs-border-radius-ratio); // HEIGHT: 48
@thumb-border-radius: var(--thumb-border-radius); // MAX: 16
@thumb-border-radius-relative: var(--thumb-border-radius-relative); // MAX: 16
@course-content-border-radius: var(--course-content-border-radius); // MAX: 32
@course-content-border-radius-relative: var(--course-content-border-radius-relative); // MAX: 32
@progress-border-radius: var(--progress-border-radius); // MAX: 16
@progress-border-radius-ratio: var(--progress-border-radius-ratio); // HEIGHT: 32

//
// Spacings
//

@global-margin-mini: .25rem; // 4px
@global-margin-small: .5rem; // 8px
@global-margin: .75rem; // 12px
@global-margin-medium: 1rem; // 16px
@global-margin-medium-2: 1.25rem; // 20px
@global-margin-large: 1.5rem; // 24px
@global-margin-large-2: 2rem; // 32px
@global-margin-xlarge: 3rem; // 48px

@global-grid-gutter: 1.5rem;
@global-grid-gutter-large: 2rem;


//
// Controls
//

@global-height: 3rem; // 48px
@global-height-mini: 1.5rem; // 24px
@global-height-small: 2.25rem; // 36px
@global-height-large: 4rem; // 64px

//
// Sizes
//

@global-size-tiny: 1rem; // 16px
@global-size-mini: 1.5rem; // 24px
@global-size-small: 2rem; // 32px
@global-size-medium: 3rem; // 48px
@global-size-large: 4rem; // 64px

//
// Icons sizes
//

@fa-fw: 1.28571429; // .fa-fw
@md-icon-size: 1.5rem;
@md-icon-size-small: 1.25rem;
@md-icon-size-xsmall: 1rem;

//
// Images path
//
@global-images-path: '../images/';

//
// Z-index
//

@global-z-index: 1000;

//
// Transition
//

@global-hover-transition: opacity .135s cubic-bezier(0, 0, .2, 1);


//
// CSS custom properties
//

:root {
  // Course version history.
  --course-version-comment-textarea-width: 30vw;

  @media (min-width: @breakpoint-medium) {
    --course-version-comment-textarea-width: calc(30 * 930px / 100); // @modal-dialog-large-width
  }

  @media (min-width: @breakpoint-xlarge) {
    --course-version-comment-textarea-width: calc(30 * 1130px / 100); // @modal-dialog-large-width-large
  }

  // Timeline.
  --timeline-item-course-width: 17.25rem;
  --timeline-item-location-width: 17.25rem;
  --timeline-item-user-size: 3rem;

  @media (max-width: @breakpoint-medium + ((@breakpoint-large - @breakpoint-medium) / 2)) {
    --timeline-item-course-width: 14.75rem;
  }

  @media (max-width: @breakpoint-small-max) {
    --timeline-item-course-width: 12.75rem;
    --timeline-item-location-width: 9.75rem;
    --timeline-item-user-size: 2.5rem;
  }

  @media (max-width: @breakpoint-small + ((@breakpoint-medium - @breakpoint-small) / 2)) {
    --timeline-item-course-width: 10.75rem;
  }

  @media (max-width: @breakpoint-mini-max) {
    --timeline-item-course-width: 7.75rem;
  }

  @media (max-width: 375px) {
    --timeline-item-course-width: 6.75rem;
  }

  // Charts.
  --charts-bg-color: var(--charts-bg-color); // It's set in colors.less
  --charts-color-1: #332288;
  --charts-color-2: #117733;
  --charts-color-3: #44aa99;
  --charts-color-4: #88ccee;
  --charts-color-5: #ddcc77;
  --charts-color-6: #cc6677;
  --charts-color-7: #aa4499;
  --charts-color-8: #882255;
}
