.mtx-statistics-table {
  table {
    margin: 0;
  }

  tr {
    &:hover {
      td.active {
        background: none;
      }
    }
  }

  th.active a {
    display: inline-block;
    position: relative;

    .md-icon {
      position: absolute;
      top: 0;
    }

    [dir=ltr] & {
      padding-right: @md-icon-size;

      .md-icon {
        right: 0;
      }
    }

    [dir=rtl] & {
      padding-left: @md-icon-size;

      .md-icon {
        left: 0;
      }
    }
  }

  th, td {
    word-break: break-word;
  }

  .views-field {
    &-title {
      min-width: 10rem;
      max-width: 15rem;
    }

    &-status {
      min-width: 8rem;
      max-width: 8rem;
    }

    &-instructor {
      min-width: 10rem;
      max-width: 10rem;
    }

    &-signups,
    &-certificate,
    &-certificate-1,
    &-certificate-2 {
      min-width: 7rem;
      max-width: 7rem;
    }
  }

  & + .item-list .pager-load-more {
    margin-top: @global-margin-medium * 2;
  }

  &.mtx-statistics-instructor-h5p-tests-table {
    th, td {
      min-width: 10rem;

      &:last-child {
        white-space: nowrap;
      }
    }
  }
}

.mtx-statistics-details {
  &.pages,
  &.videos,
  &.audios {
    .views-field {
      &-viewed,
      &-average {
        min-width: 7rem;
      }

      &-first-access,
      &-last-access {
        min-width: 8rem;
      }
    }
  }

  &.tests .mtx-statistics-scorm-xapi-test-item {
    th,
    td {
      vertical-align: top;
    }

    .views-field {
      &-started {
        min-width: 10rem;
        word-break: break-word;
      }

      &-total-time,
      &-certification {
        min-width: 8rem;
        word-break: break-word;
      }

      &-completion-status,
      &-success-status,
      &-score,
      &-details {
        min-width: 9rem;
      }
    }
  }
}

// Activities.
.mtx-statistics-activities {
  .filter-badges {
    clear: both;
  }

  .mtx-statistics-table {
    th,
    td {
      vertical-align: top;
    }

    .views-field-title {
      min-width: 15rem;
    }
  }
}

// Participants.
.mtx-statistics-learners,
.mtx-statistics-learners-scorm {
  .mtx-statistics-table {
    .views-field {
      min-width: 10rem;
    }

    .views-field-mtx-certificate {
      min-width: 12rem;
    }

    @media (max-width: @breakpoint-small) {
      .views-field-statistics-id-2 {
        width: 150px;
      }
    }
  }
}

.mtx-statistics-learners {
  .mtx-statistics-table {
    td.views-field-field-full-name {
      @avatar-size: 3rem; // @see avatar params in mtx_statistics_preprocess_views_view_table

      max-width: 15rem;

      .text {
        max-width: calc(100% - @avatar-size - @global-margin-large);

        div {
          .text-ellipsis();
        }
      }

      .mtx-avatar-icon {
        max-width: @avatar-size;
        min-width: @avatar-size;
      }
    }
  }
}

.mtx-statistics-learner-progress {
  max-width: 5rem; // 80px

  .mtx-progress-value {
    font-size: @global-font-size-small;

    [dir=ltr] & {
      padding-left: @global-margin-small;
    }

    [dir=rtl] & {
      padding-right: @global-margin-small;
    }
  }
}

.mtx-statistics-element-details {
  &-container .mtx-custom-scrollbar {
    max-height: 400px;
  }

  &-modal {
    .mtx-statistics-table {
      th, td {
        vertical-align: top;
      }

      th:first-child {
        width: 25%;
      }
    }

    .mtx-statistics-table.mtx-statistics-h5p-answers-table {
      th:first-child {
        width: 75%;
      }

      &.instructor-view th:first-child {
        width: 50%;
      }
    }
  }

  &-filter {
    margin-bottom: @global-margin-medium;

    select {
      width: auto;
      height: @form-height;
      margin: 0;
      font-size: @global-font-size;
      font-family: inherit;
    }
  }
}

.mtx-statistics-tincan-statements {
  th,
  td {
    vertical-align: top;
  }

  pre {
    display: none;
    word-break: break-word;
    word-wrap: break-word;
    white-space: pre-wrap;
  }

  .time {
    width: 10rem;
    white-space: nowrap;
  }
}

.mtx-statistics-attempt-status {
  // Reset font-weight for tables on page.
  .mtx-statistics-page & {
    font-weight: @global-font-weight-normal;
  }
}

.statistics-status-icon {
  @size: @global-height / 3;

  .circle(@size);
  min-width: @size;
  background-color: @_grey_color;

  [dir=ltr] & {
    margin-right: @global-margin;
  }

  [dir=rtl] & {
    margin-left: @global-margin;
  }
}
