@timeline-padding: @global-margin-small;

@timeline-cell-padding: .125rem;
@timeline-item-padding: @timeline-padding;

@timeline-item-line-height: 2.5;
@timeline-item-font-size: @global-font-size-small;

.timeline {
  position: relative;
  overflow: hidden;
  padding: 0;
  .clearfix;

  &,
  &-cell {
    background-image: linear-gradient(@_grey_light_color 50%, transparent 0%);
    background-size: 1px 16px;
    background-repeat: repeat-y;
  }

  &-cell {
    height: 100%;
    white-space: nowrap;
    padding: 0 0 @global-margin;

    &.timeline-highlighted,
    &.timeline-weekend {
      background-color: fade(#0e9f46, 8%); // https://adobe.ly/3mUdyNw

      .mtx-dark-theme & {
        background-color: @overlay-color-lighten;
      }
    }
  }

  &-cell-heading {
    padding: @timeline-cell-padding;
    text-align: center;
    font-size: @timeline-item-font-size;

    & > span {
      .text-ellipsis;
      display: inline-block;
      width: auto;
      max-width: 100%;
      text-align: center;
      padding: (@timeline-padding / 2) 0;
      .button-border-radius(@timeline-item-font-size * @global-line-height + @timeline-padding);

      .timeline-today & {
        background-color: @_grey_lighten_color;
        padding: (@timeline-padding / 2) @timeline-padding;

        .mtx-dark-theme & {
          background-color: @_grey_darken_color;
        }
      }
    }

    .timeline-today &.is-hidden {
      display: none;
    }

    @media all and (max-width: @breakpoint-small-max) {
      span.label {
        display: none;
      }
    }
  }

  &-empty {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: @_grey_color;
    line-height: @global-line-height;
    padding: 5% @global-margin-medium;

    p {
      display: inline-block;
      border-radius: @global-border-radius;
      margin: 0;
      padding: @timeline-padding;
    }
  }

  &-item {
    position: absolute;
    padding: @timeline-cell-padding;
    font-size: @timeline-item-font-size;
    line-height: @timeline-item-line-height;

    a {
      display: block;
      color: inherit !important;
      outline: none;
      padding: @timeline-padding;
      border-radius: @global-border-radius;

      .mtx-light-theme & {
        background-color: @_grey_lighten_color;

        &:hover,
        &:focus {
          background-color: @_grey_light_color;
        }
      }

      .mtx-dark-theme & {
        background-color: @_grey_dark_color;

        &:hover,
        &:focus {
          background-color: darken(@_grey_dark_color, 6%);
        }
      }

      .md-icon {
        vertical-align: bottom;
      }

      div {
        .text-ellipsis;
      }
    }

    &.overdue a {
      color: #f82a19 !important;
    }

    &.completed a {
      color: @_grey_color !important;
      text-decoration: line-through;
    }

    [dir=ltr] & {
      &-cut-start {
        padding-left: 0;

        a {
          padding-left: @timeline-item-padding + @timeline-cell-padding !important; // prevent text jumping on hover.
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }
      }

      &-cut-end {
        padding-right: 0;

        a {
          padding-right: @timeline-item-padding + @timeline-cell-padding !important; // prevent text jumping on hover.
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }
      }
    }

    [dir=rtl] & {
      &-cut-start {
        padding-right: 0;

        a {
          padding-right: @timeline-item-padding + @timeline-cell-padding; // prevent text jumping on hover.
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }
      }

      &-cut-end {
        padding-left: 0;

        a {
          padding-left: @timeline-item-padding + @timeline-cell-padding; // prevent text jumping on hover.
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }
      }
    }
  }

  &-group-item {
    position: absolute;
    padding-top: @timeline-cell-padding;
    color: inherit !important;
  }

  &-filters {
    margin-bottom: @global-margin-large;
  }

  &-header {
    margin-bottom: @global-margin-large !important;
    clear: both;

    & > div {
      display: inline-block;
    }

    &-title {
      vertical-align: middle;
      padding: @timeline-padding 0;
    }

    .timeline-controls {
      white-space: nowrap;
    }

    .timeline-control {
      display: inline-block;
      text-align: center;
      .button-border-radius(@timeline-padding + @md-icon-size);
      padding: @timeline-padding / 2;
      color: @_grey_dark_color !important;
      background-color: transparent;
      transition: all .1s ease-in-out;

      &-close {
        background-color: @global-page-background-color;
        color: @_grey_darken_color !important;
      }

      &-today {
        .button-border-radius(@timeline-padding + (@global-font-size-small * @global-line-height));
        padding: (@timeline-padding / 2) @timeline-padding;
        font-size: @global-font-size-small;
        color: inherit !important;
      }

      &:hover,
      &:focus {
        .mtx-light-theme & {
          background-color: @_grey_lighten_color;
        }

        .mtx-dark-theme & {
          background-color: darken(@_grey_dark_color, 6%);
        }
      }
    }
  }

  &-show-more {
    .circle(@button-small-height);
    margin: @global-margin auto;
    border-radius: @button-border-radius-small;
    text-align: center;

    .md-icon {
      line-height: @button-small-height;
    }
  }

  &.grouped-by {
    overflow: visible;

    &.collapsed {
      overflow-y: clip;
      overflow-x: visible;
    }

    &.user {
      .timeline-group-item {
        max-width: var(--timeline-item-user-size);
      }
    }

    &.course {
      .timeline-group-item {
        max-width: var(--timeline-item-course-width);
      }
    }

    &.location {
      .timeline-group-item {
        border-radius: @global-border-radius;
        padding: @timeline-item-padding;
        min-height: 80px;
        z-index: 1;

        &.active,
        &:hover,
        &:focus {
          background-color: fade(@_grey_color, 10%);

          .mtx-dark-theme & {
            background-color: fade(@_white_color, 12%);
          }
        }
      }

      .timeline-item {
        font-size: @global-font-size-xsmall;
        line-height: 1;
        padding-top: @timeline-item-padding;
        padding-bottom: @timeline-item-padding;
      }
    }
  }

  &.collapsed {
    overflow-y: clip;

    & + .timeline-show-more .md-expand_less:after {
      content: '\e5cf'; // md-expand_more
    }
  }

  &-empty p {
    background-color: @mtx-section-even-bg-color;

    .mtx-dark-theme & {
      background-color: @mtx-section-even-bg-color-dark;
    }
  }

  [dir=ltr] & {
    background-position-x: right;

    &-cell {
      float: left;
    }

    &-header {
      .timeline-title {
        margin-right: @timeline-padding;
      }
    }

    &.grouped-by {
      &.user {
        margin-left: calc(var(--timeline-item-user-size) + @global-margin-large);

        .timeline-group-item {
          left: calc((var(--timeline-item-user-size) + @global-margin-large) * -1);
        }
      }

      &.course {
        margin-left: calc(var(--timeline-item-course-width) + @global-margin-large);

        .timeline-group-item {
          left: calc((var(--timeline-item-course-width) + @global-margin-large) * -1);
        }
      }

      &.location {
        margin-left: calc(var(--timeline-item-location-width) + @global-margin-large);

        .timeline-group-item {
          left: calc((var(--timeline-item-location-width) + @global-margin-large + @timeline-item-padding) * -1);
          right: -@timeline-item-padding;
        }
      }

      &.empty {
        margin-left: 0;
      }
    }
  }

  [dir=rtl] & {
    background-position-x: right;

    &-cell {
      float: right;
    }

    &-header {
      .timeline-title {
        margin-left: @timeline-padding;
      }
    }

    &.grouped-by {
      &.user {
        margin-right: calc(var(--timeline-item-user-size) + @global-margin-large);

        .timeline-group-item {
          right: calc((var(--timeline-item-user-size) + @global-margin-large) * -1);
        }
      }

      &.course {
        margin-right: calc(var(--timeline-item-course-width) + @global-margin-large);

        .timeline-group-item {
          right: calc((var(--timeline-item-course-width) + @global-margin-large) * -1);
        }
      }

      &.location {
        margin-right: calc(var(--timeline-item-location-width) + @global-margin-large);

        .timeline-group-item {
          right: calc((var(--timeline-item-location-width) + @global-margin-large + @timeline-item-padding) * -1);
          left: -@timeline-item-padding;
        }
      }

      &.empty {
        margin-right: 0;
      }
    }
  }

  @media (max-width: @breakpoint-small-max) {
    &-header {
      .timeline-control-today {
        display: none;
      }
    }

    &-today &-cell-heading {
      display: none;

      &.is-hidden {
        display: block;
      }
    }
  }

  @media all and (max-width: @breakpoint-medium-max) {
    &.two-weeks &-cell-heading {
      span.label {
        display: none;
      }
    }

    &-today &-cell-heading {
      display: none;

      &.is-hidden {
        display: block;

        & > span {
          background-color: transparent;
          padding: (@timeline-padding / 2) 0;
        }
      }
    }
  }

  @media all and (max-width: @breakpoint-large-max) {
    &.grouped-by.course {
      .timeline-cell-heading {
        span.label {
          display: none;
        }
      }

      .timeline-today .timeline-cell-heading {
        display: none;

        &.is-hidden {
          display: block;

          & > span {
            background-color: transparent;
            padding: (@timeline-padding / 2) 0;
          }
        }
      }
    }
  }
}

// Location availability timeline
#mtx-location-timeline-popup {
  .uk-modal-dialog {
    padding-top: 2 * @global-margin-large;
    padding-bottom: @global-margin-large;
  }

  .timeline-header {
    &,
    &-title {
      display: flex;
      align-items: center;
    }

    .page-title {
      margin-bottom: 0;
    }

    [dir=ltr] & {
      .timeline-control-today {
        margin-left: @global-margin-large;
      }

      .timeline-control-close {
        margin-left: auto;
      }

      &-title,
      .page-title {
        margin-right: @global-margin-xlarge;
      }
    }

    [dir=rtl] & {
      .timeline-control-today {
        margin-right: @global-margin-large;
      }

      .timeline-control-close {
        margin-right: auto;
      }

      &-title,
      .page-title {
        margin-left: @global-margin-xlarge;
      }
    }
  }

  .timeline-item a {
    text-decoration: none;
  }

  .timeline-group-item {
    text-decoration: none;

    .title {
      text-decoration: @base-link-text-decoration;
    }

    &:hover,
    &:focus {
      .title {
        text-decoration: @base-link-hover-text-decoration;
      }
    }
  }

  .mtx-location-timeline-item {
    .icon-holder {
      width: @md-icon-size;

      [dir=ltr] & {
        margin-right: @global-margin;
      }

      [dir=rtl] & {
        margin-left: @global-margin;
      }
    }

    .content-wrapper {
      overflow: hidden;
    }

    .title {
      width: calc(var(--timeline-item-location-width) - 2 * @timeline-item-padding - @global-margin);
      font-size: @global-font-size-small;
      font-weight: @global-font-weight-normal;
      margin-bottom: 0;
      .text-ellipsis-box(2);
    }
  }
}
