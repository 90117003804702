// Content page.
.mtx-appearance-content-form {
  .form-item-base-font,
  .form-item-base-font-gwf,
  .form-item-base-font-cfs {
    display: none;
  }

  #edit-favicon-upload-upload {
    img[src*='svg'],
    img[src*='apng'] {
      // Logo sizes.
      max-width: 100px;
      max-height: 63px;
    }
  }

  .roundness-settings {
    input[type="range"] {
      & + .field-suffix .value {
        min-width: 2ch;
      }
    }
  }

  @media all and (min-width: @breakpoint-large) {
    // Override base.less styles
    .form-type-select {
      width: auto;
    }
  }

  @media all and(max-width: @breakpoint-small) {
    .mtx-slides-set > * {
      width: 50%;
    }
  }
}

#edit-font-subtitles {
  .form-item {
    input {
      width: 100%;
    }

    .file-uploaded-info-wrapper input {
      width: auto;
    }

    .sp-replacer {
      top: auto;
      bottom: @form-padding-y;
    }
  }

  & > .form-wrapper {
    margin: 0;
  }

  .form-item-subtitle-transparency {
    position: relative;

    @suffix-size: @form-height - .5rem;

    .field-suffix {
      color: @_grey_dark_color;
      position: absolute;
      bottom: .25rem;
      line-height: @suffix-size;
      width: @suffix-size;
      text-align: center;
    }

    input {
      padding-inine-end: @suffix-size;
    }

    [dir=ltr] & {
      .field-suffix {
        right: 0;
      }
    }

    [dir=rtl] & {
      .field-suffix {
        left: 0;
      }
    }
  }
}

#edit-font-subtitles > .form-wrapper,
#edit-nameplates-text {
  .form-item {
    margin: 0 0 @global-margin;

    select {
      width: 100%;
    }

    label {
      .text-ellipsis();
    }
  }

  & > .form-item {
    @media all and (min-width: @breakpoint-small) {
      width: 32%;

      & + .form-item {
        margin-inline-start: 2%;
      }

      [dir=ltr] & {
        float: left;
      }

      [dir=rtl] & {
        float: right;
      }
    }
  }
}

// Colors page.
.mtx-appearance-colors-form {
  .form-item-advanced-settings {
    .clearfix;
    margin: 2.5rem 0 1.875rem;
    position: relative;

    input {
      // setting display: none makes not possible to navigate by keyboard.
      position: absolute;
      opacity: 0;
      width: 1px;
      height: 1px;

      &:checked + label .md-icon:after {
        content: '\e5ce'; // md-expand_less
      }

      &:focus-visible + label {
        .focus-state-box-shadow();
        text-decoration: @base-link-hover-text-decoration;
      }
    }

    label {
      margin: 0 !important;
      cursor: pointer;
      text-decoration: @base-link-text-decoration;

      &:hover {
        text-decoration: @base-link-hover-text-decoration;
      }
    }
  }

  .advanced > .form-wrapper {
    display: none;

    @media all and (min-width: @breakpoint-medium) {
      & + .form-wrapper {
        margin-top: 0;
      }
    }
  }

  @media all and (min-width: @breakpoint-medium) and (max-width: @breakpoint-medium-max) {
    .simple,
    .advanced {
      width: 75%;
    }
  }
}

// Top banner settings.
.mtx-appearance-top-banner-form {
  .form-item-top-banner-height-custom {
    display: flex;
    align-items: center;

    input {
      width: @field-type-number-width;
    }
  }

  .form-item-top-banner-height-custom input,
  .form-item-top-banner-height {
    [dir=ltr] & {
      margin-right: @global-margin;
    }

    [dir=rtl] & {
      margin-left: @global-margin;
    }
  }

  .form-item-top-banner-background-color {
    .sp-replacer {
      // Reset default styles
      top: auto;
      left: auto;
      right: auto;

      [dir=ltr] & {
        bottom: @form-padding-y;
        left: @palette-color-field-width - @palette-preview-size - @form-padding-y;
      }

      [dir=rtl] & {
        bottom: @form-padding-y;
        right: @palette-color-field-width - @palette-preview-size - @form-padding-y;
      }
    }
  }

  .form-item-top-banner {
    img {
      border-radius: @thumb-border-radius;
    }
  }
}
