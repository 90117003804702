//
// Component: Text
//
// ========================================================================

// Variables
// ========================================================================

@text-small-font-size:                          @global-font-size-small;
@text-small-line-height:                        1.49;

@text-xsmall-font-size:                         @global-font-size-xsmall;
@text-xsmall-line-height:                       1.49;

@text-large-font-size:                          @global-font-size * 1.28;
@text-large-line-height:                        @text-large-font-size * 1.32;

@text-muted-color:                              @global-muted-color;
@text-primary-color:                            #0072cd;
@text-success-color:                            #14a014;
@text-warning-color:                            #ffab00;
@text-danger-color:                             #ee0000;
@text-contrast-color:                           @global-contrast-color;


// Miscellaneous
// ========================================================================

.hook-text-misc() {
  .uk-text-xsmall {
    font-size: @text-xsmall-font-size;
    line-height: @text-xsmall-line-height;

    table & {
      font-size: @text-xsmall-font-size / @table-font-size;
    }
  }

  .uk-text-small {
    .table & {
      font-size: @text-small-font-size / @table-font-size;
    }
  }

  // Add: `uk-text-normal`
  .uk-text-normal {
    font-weight: @global-font-weight-normal;
  }

  // Add: `uk-text-semi-bold`
  .uk-text-semi-bold {
    font-weight: @global-font-weight-semibold;
  }

  // Add: `uk-text-uppercase`
  .uk-text-uppercase {
    text-transform: uppercase !important;
  }

  [dir=ltr] &,
  [dir=rtl] [dir=ltr] & {
    .uk-text-right {
      text-align: right !important;
    }

    .uk-text-left {
      text-align: left !important;
    }
  }

  [dir=rtl] {
    .uk-text-right {
      text-align: left !important;
    }

    .uk-text-left {
      text-align: right !important;
    }
  }
}
