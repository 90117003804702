@learning-path-thumb-width: 160px;

.mtx-activities {
  // Filters.
  thead th {
    vertical-align: top;
  }

  .filters {
    .search {
      .input-search-icon;
    }
  }

  .mtx-date-field {
    input {
      height: @form-height;
    }

    .date-field-clear[disabled] {
      display: none;
    }
  }

  // Table.
  .title-cell {
    font-weight: @global-font-weight-semibold;
  }

  .activity.is-grouped {
    .title-cell {
      position: relative;
    }

    [dir=ltr] & {
      .title-cell {
        padding-right: calc(@table-cell-padding * 2 + @md-icon-size);
      }
    }

    [dir=rtl] & {
      .title-cell {
        padding-left: calc(@table-cell-padding * 2 + @md-icon-size);
      }
    }
  }

  .title-cell,
  .modules-cell,
  .schedule-cell {
    min-width: 12em;
  }

  .title-cell {
    max-width: 20em;
  }

  .time-available-cell,
  .location-cell {
    min-width: 10em;
  }

  .modules-cell,
  .location-cell,
  .time-available-cell {
    max-width: 15em;
  }

  .group-cell {
    min-width: 3em;
    text-align: center;
  }

  .type-cell,
  .status-cell {
    min-width: 7em;
    text-align: center;
  }

  .body {
    .type-cell,
    .status-cell {
      .md-icon {
        font-size: 1.25rem;
        color: @_grey_dark_color
      }
    }
  }

  .registration-cell {
    min-width: 8em;
  }

  .seats-cell,
  .self-enrollment-cell {
    min-width: 5em;
  }

  .self-enrollment-cell {
    text-align: center;

    .status {
      .circle(.625rem);
      display: inline-block;
    }
  }

  .table-action-icon-cell {
    .table-view-mode .md-icon {
      font-size: 1.25rem;
    }

    // @Todo: Remove if actions will look like on Signups list.
    // It prevents dropdown's shadow cut.
    .table-action-icon-wrapper:last-child {
      margin-inline-end: @table-action-margin-x;
    }
  }

  // States.
  &.compact-mode {
    .table-action-icon-cell {
      .table-view-mode .md-icon:after {
        content: '\efe8'; // md-calendar_view_week
      }
    }

    .type-cell,
    .registration-cell,
    .location-cell {
      display: none;
    }
  }

  .filter-by-own {
    .uk-button {
      min-width: @button-height;
    }

    & + div.filters {
      [dir=ltr] & {
        margin-left: @global-margin-medium * 2;
      }

      [dir=rtl] & {
        margin-right: @global-margin-medium * 2;
      }
    }
  }
}

// Overview page (Course admin, Assistant).
.mtx-activities-section {
  .mtx-reminders-section + &:nth-of-type(3n) {
    padding-top: 0;
  }

  .mtx-section-odd-change-following-sections-bg();

  .filter-badges {
    clear: both;
  }

  .timeline-header {
    margin-top: @global-margin-large;
  }

  .timeline-show-more {
    margin-bottom: 0;
  }

  .timeline-container,
  .table-container {
    background-color: @mtx-section-even-bg-color;

    .mtx-dark-theme & {
      background-color: @mtx-section-even-bg-color-dark;
    }
  }

  .table-container {
    padding-bottom: @mtx-section-padding - @global-margin-large; // table margin + cell padding
  }

  table.activities {
    .title-cell,
    .modules-cell {
      max-width: 15rem;
    }
  }

  &.timeline-mode {
    .timeline-container {
      padding-bottom: @mtx-section-padding;
    }

    .table-container {
      display: none !important;
    }
  }

  &.list-mode {
    .timeline-container {
      .timeline,
      .timeline-show-more {
        display: none !important;
      }
    }

    .table-container {
      margin-top: @global-margin-medium * 2 - @global-margin-large - @table-margin-y; // @global-margin-large - timeline-header margin
    }
  }

  & > .pager {
    padding-bottom: @mtx-section-padding;
  }

  @media all and (min-width: @breakpoint-medium) {
    .mtx-section-title {
      margin-bottom: 0;
    }
  }
}

// Learning path modules list.
.mtx-learning-path-item {
  .mtx-list & {
    padding: @global-margin-small 0;
  }

  &.mtx-list-item {
    .meta {
      color: inherit;
    }
  }

  .thumbnail {
    position: relative;
    width: @learning-path-thumb-width;
    min-width: @learning-path-thumb-width;
  }

  .mtx-thumb {
    &, & > img {
      border-radius: @thumb-border-radius;
    }
  }

  .mtx-progress-bar {
    border-radius: 0 0 @thumb-border-radius @thumb-border-radius;
  }

  .mtx-date-widget {
    padding-top: 56.25%;
    position: relative;
    border-radius: @thumb-border-radius;
    background-color: @overlay-color-dark;

    .mtx-dark-theme & {
      background-color: @overlay-color-light;
    }

    &-body {
      .position-center();
    }
  }

  .title {
    font-size: @base-h3-font-size;
    line-height: @base-h3-line-height;
  }

  .existing-training-options-overview {
    .uk-panel {
      padding: @global-margin-medium-2 @global-margin-large;
      border-radius: @global-border-radius-xlarge;
    }
  }

  [dir=ltr] & {
    .thumbnail {
      margin-right: @global-margin-large;
    }
  }

  [dir=rtl] & {
    .thumbnail {
      margin-left: @global-margin-large;
    }
  }

  @media all and (min-width: @breakpoint-medium) {
    .item-content .info-wrapper {
      flex: 1 1 100%;
    }

    &.has-actions {
      [dir=ltr] & {
        .item-content .info-wrapper {
          margin-right: @global-margin-large;
        }
      }

      [dir=rtl] & {
        .item-content .info-wrapper {
          margin-left: @global-margin-large;
        }
      }
    }

    .actions {
      .uk-button {
        white-space: nowrap;
      }

      [dir=ltr] & {
        margin-left: auto;
        text-align: right;
      }

      [dir=rtl] & {
        margin-right: auto;
        text-align: left;
      }
    }
  }

  @media all and (max-width: @breakpoint-small-max) {
    .item-details-wrapper {
      flex-direction: column;
    }

    .actions {
      &.mtx-button-group {
        margin-top: 0;
      }
    }
  }

  @media all and (max-width: @breakpoint-mini-max) {
    .item-content.uk-flex {
      flex-direction: column;

      .thumbnail {
        margin: 0 0 @global-margin-medium;
      }
    }
  }
}

// Learning path overview page
.mtx-learning-path-overview {
  .page-title {
    margin: @global-margin 0 @global-margin-large;
  }

  .learning-path-banner {
    margin: @global-margin auto;
    border-radius: @thumb-border-radius;
    padding-top: 56.25%; // (9 / 16) * 100
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    &.b-error {
      display: none;
    }
  }

  .learning-path-description {
    margin: @global-margin-large 0;
  }

  .modules-lp-header {
    margin: @global-margin-xlarge 0 0;
  }

  .modules-lp-filters {
    [role="search"] ~ .form-item {
      margin: 0;
    }

    .mtx-api-filter {
      margin-inline-start: @global-margin-small;
    }

    @media (max-width: @breakpoint-medium-max) {
      [dir=ltr] & {
        float: right;
      }

      [dir=rtl] & {
        float: left;
      }
    }
  }

  .mtx-learning-path-item {
    margin-top: 2 * @global-margin-medium;
  }
}

// Module instructors.
.mtx-module-instructors-list-item {
  &.mtx-list-item {
    padding-top: 0;
    padding-bottom: 0;

    .icon img {
      display: block;
      height: auto;
      overflow: hidden;
      background-color: @api-item-empty-bg;

      .mtx-dark-theme & {
        background-color: @overlay-color-darken;
      }
    }

    .title {
      font-weight: normal;
    }
  }
}

.mtx-module-instructors-training-view {
  @item-margin-bottom: @global-margin;
  @wrapper-padding-y: @global-margin-medium * 2;

  padding: @wrapper-padding-y 0 calc(@wrapper-padding-y - @item-margin-bottom);

  .mtx-module-instructors-list-heading {
    margin: 0 0 @global-margin-large;
  }

  .mtx-module-instructors-list.uk-grid {
    [dir=ltr] & {
      margin-left: -@item-margin-bottom;

      & > * {
        padding-left: @item-margin-bottom;
      }
    }

    [dir=rtl] & {
      margin-right: -@item-margin-bottom;

      & > * {
        padding-right: @item-margin-bottom;
      }
    }
  }

  .mtx-module-instructors-list-item {
    &.mtx-list-item {
      margin-top: 0;
      margin-bottom: @item-margin-bottom;
    }

    .title {
      font-size: @global-font-size-small;
    }
  }
}

.mtx-module-instructors-event-module-page-view {
  @item-margin-bottom: @global-margin-medium;
  @wrapper-padding-y: @global-margin-medium * 2;

  padding: @wrapper-padding-y 0 calc(@wrapper-padding-y - @item-margin-bottom);

  .mtx-module-instructors-list.uk-grid {
    [dir=ltr] & {
      margin-left: -@item-margin-bottom;

      & > * {
        padding-left: @item-margin-bottom;
      }
    }

    [dir=rtl] & {
      margin-right: -@item-margin-bottom;

      & > * {
        padding-right: @item-margin-bottom;
      }
    }
  }

  .mtx-module-instructors-list-item {
    &.mtx-list-item {
      margin-top: 0;
      margin-bottom: @item-margin-bottom;
    }
  }
}

// Focused tab.
.uk-sortable-dragged .mtx-activities .uk-container {
  padding: 0;
}

.optional-module {
  display: inline-block;
  border: 1px solid #015191;
  border-radius: 10px;
  color: #015191;
  font-size: @global-font-size-small;
  font-weight: @global-font-weight-semibold;

  .md-icon {
    margin: 0px 3px 3px 10px;
    font-size: @global-font-size;
  }

  .text {
    margin-right: 10px;
  }
}
