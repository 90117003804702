.nei-widget-comments {
  background-color: @_white_color;
  padding: 24px; // @see: widget.js customize method
  border-radius: @course-content-border-radius;

  .mtx-dark-theme & {
    background-color: @_black_color;
  }

  .course-comments-placeholder {
    padding: @global-margin-large 0;
  }

  .course-comments-count {
    font-size: @global-font-size;
    opacity: @widget-contrast-opacity;
  }

  #mtx-coursebuilder-course-comments {
    min-width: 425px;
  }

  #mtx-course-page-comments {
    overflow-x: auto;
  }
}
