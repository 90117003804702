// CKEDITOR Styles
.cke {
  .cke_top,
  .cke_inner,
  .cke_wysiwyg_div {
    background-color: transparent !important;
  }

  .cke_top {
    padding: 4px 4px 0;
    opacity: 0;

    a.cke_button {
      border-radius: calc(@inputs-border-radius / 2);

      &.cke_button_on {
        background-color: rgba(255, 255, 255, .4);
      }
    }
  }

  .cke_top,
  &.cke_balloon {
    a.cke_button {
      border-radius: calc(@inputs-border-radius / 2);
    }
  }

  .cke_wysiwyg_div {
    padding: @form-padding-y @form-padding-x 0;

    .with-toolbar.disable-toolbar-toggle.without-resources & {
      padding-bottom: @form-padding-y;
    }

    p:only-child,
    .with-toolbar.disable-toolbar-toggle.without-resources & p:last-child {
      margin-bottom: 0;
    }

    &[data-cke-editorplaceholder]::before {
      margin-top: 0;
      color: @global-placeholder-color;
    }

    div[data-cke-filler-webkit="start"] + * {
      margin-top: 0;
    }

    h1, h2, h3, h4, h5, h6 {
      font-weight: unset;
    }

    // H5P certificate.
    .mtx-content-section & h1 {
      font-size: @base-h1-font-size;
      line-height: @base-h1-line-height;
      margin: 0 0 @base-margin-vertical 0;
    }
  }
}

.cke_dialog {
  td,
  th {
    &.cke_dialog_contents_body {
      padding: 9px 10px 5px 10px;
    }
  }
}

.cke_reset_all.cke_dialog_container {
  font-weight: inherit;

  &, * {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
  }

  * {
    letter-spacing: initial;
  }

  & > .cke_dialog {
    margin-top: 0 !important;
    position: relative;
    top: 10% !important;
    width: @modal-dialog-width;
    max-width: 100%;
    max-width: ~"calc(100% - 20px)";

    @media (max-width: @breakpoint-small-max) {
      .uk-modal-dialog {
        width: 95%;
      }
    }
  }

  // Tabs
  .cke_dialog_tabs {
    margin: 0;
  }

  // Header
  .cke_dialog_title {
    font-size: @base-h2-font-size;
    line-height: @base-h2-line-height;
    background: transparent;
    border-bottom: 0 none;
    margin-top: 0;
    margin-bottom: @modal-header-margin-bottom;
    padding: @modal-dialog-padding @modal-dialog-padding 0;
    pointer-events: none; // Prevents dragging
  }

  .cke_dialog_close_button {
    top: @modal-dialog-padding;
    width: @close-size;
    height: @close-size;
    .button-border-radius(@close-size);
    background: 0 0;
    opacity: .3;

    &:after {
      .material-icon();
      content: '\e5cd'; // md-close
    }

    &:hover,
    &:focus {
      opacity: 0.5;
    }

    &:focus-visible {
      opacity: 1;
    }

    [dir=ltr] & {
      right: @modal-dialog-padding;
    }

    [dir=rtl] & {
      left: @modal-dialog-padding;
    }
  }

  // Content
  .cke_dialog_body {
    border-radius: @modal-dialog-border-radius;
    opacity: 0;
    transform: translateY(-100px);
    transition: opacity .3s linear, transform .3s ease-out;
  }

  .cke_dialog_contents {
    border-radius: 0 0 @modal-dialog-border-radius @modal-dialog-border-radius;
    margin-top: calc(33px + @modal-header-margin-bottom + @form-border-width); // 33px - tabs height
  }

  .cke_single_page .cke_dialog_contents {
    margin-top: 0;
    padding-top: 0;
  }

  td.cke_dialog_contents_body {
    padding: @modal-header-margin-bottom @modal-dialog-padding 0;
  }

  .cke_dialog_ui_vbox_child {
    padding: 0 !important;
  }

  // Footer
  .cke_dialog_footer {
    background: transparent;
    border-top: 0 none;
    padding: @modal-footer-margin-top @modal-dialog-padding @modal-dialog-padding;
  }

  .cke_dialog_footer_buttons {
    margin: -@button-group-margin-y 0 0;

    .cke_dialog_ui_hbox > td {
      padding-top: @button-group-margin-y;
      padding-inline-start: @button-group-margin-x;
      padding-inline-end: 0;
    }
  }

  // Buttons
  a.cke_dialog_ui_button_cancel,
  a.cke_dialog_ui_button_ok {
    transition: all .15s ease-in-out;
  }

  a.cke_dialog_ui_button_cancel {
    min-height: @button-height;
    padding: calc(@button-padding-vertical - 1px) calc(@button-padding-horizontal - 1px);
    border: 1px solid @global-border-color;
    border-radius: @button-border-radius;
    background: 0 0;

    span {
      font-size: inherit;
      font-weight: inherit;
      padding: 0;
    }
  }

  a.cke_dialog_ui_button_ok {
    min-height: @button-height;
    padding: calc(@button-padding-vertical - 1px) calc(@button-padding-horizontal - 1px);
    border: 1px solid transparent;
    border-radius: @button-border-radius;

    span {
      font-size: inherit;
      font-weight: inherit;
      padding: 0;
    }

    &:active,
    &:focus,
    &:hover {
      box-shadow: none;
    }
  }

  // Label
  label {
    margin: 0;
    font-weight: @global-font-weight-semibold;
  }

  .cke_dialog_ui_labeled_required {
    padding-inline-start: 4px;
  }

  // Select
  select.cke_dialog_ui_input_select {
    @svg: escape('<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="@{form-icon-color}"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 5.83L15.17 9l1.41-1.41L12 3 7.41 7.59 8.83 9 12 5.83zm0 12.34L8.83 15l-1.41 1.41L12 21l4.59-4.59L15.17 15 12 18.17z"/></svg>');

    appearance: none;
    padding-top: @form-padding-y;
    padding-bottom: @form-padding-y;
    padding-inline-start: @form-padding-x;
    padding-inline-end: @md-icon-size;
    background-color: @form-background;
    background-image: url('data:image/svg+xml;charset=UTF-8,@{svg}');
    background-size: @md-icon-size @md-icon-size;
    background-repeat: no-repeat;
    height: @form-height;
    max-width: 100%;
    width: 100% !important;
    margin: 0;
    border: @form-border-width solid @form-border;
    border-radius: @inputs-border-radius;
    transition: @form-border-transition;

    [dir=ltr] &,
    [dir=rtl] [dir=ltr] & {
      background-position: calc(100% - @form-border-width) calc((@md-icon-size / 2) - @form-border-width);
    }

    [dir=rtl] & {
      background-position: @form-border-width calc((@md-icon-size / 2) - @form-border-width);
    }

    &:focus {
      border-color: @form-focus-border;
    }
  }

  div.mtx-text-editor-dialog-token-select {
    &.cke_dialog_ui_select {
      margin: 0 0 @global-margin-large;
    }

    .cke_dialog_ui_input_select {
      width: 100%;
    }
  }

  // Input
  input[class*="cke_dialog_ui_input_"] {
    height: @form-height;
    max-width: 100%;
    padding: @form-padding;
    border: @form-border-width solid @form-border;
    border-radius: @inputs-border-radius;
    background-color: @form-background;
    transition: all linear 0.2s;

    &:focus {
      border-color: @form-focus-border;
    }
  }

  span.cke_dialog_ui_checkbox {
    margin: @global-margin-small 0;
  }

  input.cke_dialog_ui_checkbox_input {
    appearance: none;
    height: @form-advanced-size;
    width: @form-advanced-size;
    margin: 0;
    background-color: @form-background;
    cursor: pointer;
    outline: none;
    .material-icon();

    &:before {
      display: block;
      content: @form-advanced-checkbox-blank-icon;
      font-family: inherit !important;
      font-size: inherit !important;
    }

    &:checked:before {
      content: @form-advanced-checkbox-icon;
    }

    &:indeterminate:before {
      content: @form-advanced-checkbox-indeterminate-icon;
    }

    & + label {
      display: inline;
      font-weight: @global-font-weight-normal;
    }
  }

  // Textarea
  textarea.cke_dialog_ui_input_textarea {
    padding: @form-padding;
    border: @form-border-width solid @form-border;
    border-radius: @inputs-border-radius;
    background-color: @form-background;
    transition: all linear 0.2s;
    vertical-align: top;
    appearance: none;

    &:focus {
      border-color: @form-focus-border;
    }
  }

  // Misc
  div.cke_dialog_ui_select,
  div.cke_dialog_ui_text,
  div.cke_dialog_ui_tel,
  div.cke_dialog_ui_textarea {
    margin: @global-margin 0;
  }

  // Ltr/Rtl
  .cke_ltr {
    .cke_dialog_footer {
      text-align: right;
    }
  }
}

.cke_dialog_background_cover {
  transition: opacity 0.15s linear;
  transform: translateZ(0);
}

body:not(.cke-dialog-animation-on) {
  .cke_dialog_background_cover {
    opacity: 0 !important;
  }
}

body.cke-dialog-animation-on {
  .cke_reset_all.cke_dialog_container .cke_dialog_body {
    opacity: 1;
    transform: translateY(0);
  }
}

.cke_toolgroup {
  &:not(:last-child) {
    padding-inline-end: 8px !important;
  }

  a.cke_button {
    border: 1px solid transparent;
    padding: 4px 6px !important;

    .cke_ltr & {
      &,
      &:hover,
      &:focus,
      &.cke_button_on {
        &:last-child:after {
          top: 0 !important;
          right: -6px !important;
        }
      }
    }

    .cke_rtl & {
      &,
      &:hover,
      &:focus,
      &.cke_button_on {
        &:last-child:after {
          top: 0 !important;
          left: -6px !important;
          right: auto !important;
        }
      }
    }
  }

  a.cke_button_on {
    border-color: #bcbcbc;
  }
}

.cke_button_icon {
  .nei-widget-auto-color-dark &,
  .mtx-dark-theme & {
    &,
    & ~ .cke_button_arrow {
      filter: invert(1);
    }
  }

  a.cke_button:hover &,
  a.cke_button:focus &,
  a.cke_button.cke_button_on & {
    .nei-widget-auto-color-dark &,
    .mtx-dark-theme & {
      filter: unset;
    }

    .nei-widget-auto-color-dark & ~ .cke_button_arrow,
    .mtx-dark-theme & ~ .cke_button_arrow {
      filter: unset;
    }
  }
}

.cke_combo_button {
  .mtx-dark-theme & {
    .cke_combo_arrow {
      filter: invert(1);
    }
  }

  &:hover,
  &:focus,
  .cke_combo_on & {
    .mtx-dark-theme & {
      .cke_combo_arrow {
        filter: unset;
      }
    }
  }
}

a.cke_button_disabled {
  &:hover,
  &:focus,
  &:active {
    border: 1px solid transparent !important;
  }
}

.form-type-mtx-text-editor {
  .form-item {
    margin: 0;
  }

  .cke.cke_chrome {
    border: 0 none;
  }

  textarea {
    height: @form-height - @form-padding-y;
    border-color: transparent;
    min-height: auto;
    visibility: hidden;
  }

  fieldset.filter-wrapper {
    display: none;
  }

  .mtx-text-resources {
    margin-top: calc((@inputs-border-radius + @form-padding-y) * -1px);
    // Use CSS max() function instead of less.
    padding: ~"max("@form-padding-y~", "@inputs-border-radius~")" @global-margin 0;
    border: @form-border-width solid;
    border-top: 0 none;
    border-radius: 0 0 @inputs-border-radius @inputs-border-radius;
  }

  .form-textarea-wrapper,
  .with-toolbar.disable-toolbar-toggle.without-resources .form-textarea-wrapper {
    border: @form-border-width solid;
    border-radius: @inputs-border-radius;
  }

  .with-toolbar .form-textarea-wrapper {
    border-bottom: 0 none;
    border-radius: @inputs-border-radius @inputs-border-radius 0 0;
  }

  .cke .cke_top,
  .cke .cke_toolgroup a.cke_button:last-child:after,
  .cke .cke_combo:after,
  .mtx-text-resources,
  .form-textarea-wrapper {
    border-color: @form-border;

    .nei-widget-auto-color-dark & {
      border-color: fade(@_white_color, @widget-contrast-opacity * 100%);
    }

    .nei-widget-auto-color-light & {
      border-color: fade(@_black_color, @widget-contrast-opacity * 100%);
    }
  }

  &.error {
    .form-textarea-wrapper,
    .mtx-text-resources {
      border-color: @form-danger-border !important;
    }
  }
}

.mtx-text-editor-actions {
  .reset-list;
  position: relative;
  width: 100%;

  .uk-modal-dialog & {
    float: none;
  }

  li {
    margin-inline-end: @global-margin-medium;
    // In some cases an empty html for resources actions is rendered (e.g. automation rules form).
    // So we need to be sure margins appear just in case there are some actions present.
    margin-top: @global-margin;
  }

  a {
    display: block;

    .fill {
      fill: @global-muted-color;
    }

    &:hover,
    &:focus {
      .fill {
        fill: @global-link-hover-color;
      }
    }

    .nei-widget-auto-color & {
      color: inherit;

      & > .md-icon {
        color: inherit;
        opacity: @widget-contrast-opacity;
      }

      &:hover .md-icon,
      &:focus .md-icon {
        color: inherit;
        opacity: @widget-contrast-opacity-hover;
      }

      &.active {
        & > .md-icon {
          color: inherit;
          opacity: 1;
        }
      }

      .fill {
        fill: currentColor;
        opacity: @widget-contrast-opacity;
      }

      &:hover,
      &:focus {
        .fill {
          fill:currentColor;
          opacity: @widget-contrast-opacity-hover;
        }
      }
    }

    &[data-action="toolbar"],
    &[data-action="video"] {
      & > .md-icon {
        font-size: @md-icon-size * 1.125;
      }
    }
  }

  .uk-button-dropdown {
    position: static;

    .uk-form {
      .input-search-icon;
      margin: @global-margin;

      .search-input {
        display: block;
        width: 100%;
      }
    }

    .uk-dropdown {
      max-width: 500px;

      &:before,
      &:after {
        display: none;
      }

      li {
        margin: 0;
      }

      .holder {
        padding: @global-margin @global-margin-medium @global-margin;
      }

      @media all and (min-width: @breakpoint-small) {
        min-width: 325px;
        width: 55vw;
      }

      @media all and (min-width: @breakpoint-large) {
        width: 30vw;
      }

      @media all and (min-width: @breakpoint-xlarge) {
        width: 35vw;
      }

      @media all and (max-width: @breakpoint-mini-max) {
        min-width: calc(100vw - @grid-gutter-horizontal * 2);
      }
    }

    .banner {
      position: relative;
      height: unit(100 * 280 / 440, px); // statistics
      max-width: 115px;

      img {
        height: 100%;
        width: auto;
        border-radius: calc(@thumb-border-radius * .5);
      }

      [dir=ltr] & {
        margin: 0 @global-margin 0 0;
      }

      [dir=rtl] & {
        margin: 0 0 0 @global-margin;
      }
    }

    .title,
    .meta {
      font-size: unit(@global-font-size-small, rem);
      line-height: 1.5;
    }

    .title {
      margin: 0;
      font-weight: @global-font-weight-normal;
      .text-ellipsis-box(2);
    }

    .meta {
      margin-top: @global-margin-small;
      color: @global-muted-color;
      .text-ellipsis-box(2);

      p {
        display: inline-block;
        margin: 0;
      }

      p + p:before {
        content: '|';
        margin: 0 @global-margin-small;
      }
    }

    .empty {
      margin: 0 @global-margin @global-margin-medium;
      font-size: unit(@global-font-size-small, rem);
      color: @global-muted-color;
    }
  }

  .uk-modal & {
    .uk-button-dropdown .uk-dropdown {
      width: 100%;
      max-width: 100%;
      margin-bottom: @global-margin-large;
    }
  }
}

.mtx-comment-form {
  margin: @global-margin 0;

  .add-comment-button,
  .mtx-text-editor-actions {
    display: none;
  }

  &.show-actions {
    .add-comment-button,
    .mtx-text-editor-actions {
      display: block;
    }
  }
}

.mtx-text-editor-external-resource-modal {
  .video-wrapper {
    position: relative;
    padding-top: 56.25%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
}

.without-toolbar {
  .cke_top {
    opacity: 1;
  }

  .cke .cke_wysiwyg_div {
    padding: @form-padding-y @form-padding-x;
  }
}

.with-toolbar.disable-toolbar-toggle .cke_top {
  opacity: 1;
}

.mtx-text-editor-content {
  word-wrap: break-word;

  .uk-form & {
    font-size: inherit;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: unset;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  ul {
    list-style: initial;
  }
}

.mtx-text-editor {
  &-avatar {
    width: @list-icon-size-small;
    margin-top: @form-item-margin-top;
    margin-inline-end: @list-icon-margin;
    flex: 1 0 auto;

    a {
      display: block;
      border-radius: @global-border-radius-circle;
    }
  }

  &-avatar-wrapper {
    & > .form-item {
      flex: 1 0 calc(100% - (@list-icon-size-small + @list-icon-margin));
    }
  }
}

.cke_balloontoolbar {
  .cke_balloon_triangle_outer {
    border-color: @form-border transparent !important;
  }
}

// CKEDITOR custom plugins.
// Image plugin.
.cke_widget_customeasyimage {
  .clearfix;

  &.cke_widget_wrapper_uploading {
    img {
      opacity: .75;
    }

    .customeasyimage-remove-button {
      opacity: 0;
      visibility: hidden;
    }
  }

  &:not(.cke_widget_wrapper_uploading) img {
    transition: opacity 0.3s ease-in-out;
  }

  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }
}

.cke_widget_element.customeasyimage {
  position: relative;

  &:not(.customeasyimage-align-center) {
    margin: 0;
  }
}

.cke_loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: @mtx-progress-bar-bg-color;

  .mtx-dark-theme & {
    background: @mtx-section-even-bg-color-dark;
  }

  .cke_bar {
    height: 10px;
    width: 0;
  }
}

.customeasyimage-align-right,
.customeasyimage-align-left {
  max-width: 50%;
}

.customeasyimage-align-right {
  float: right;
}

.customeasyimage-align-left {
  float: left;
}

.customeasyimage-align-center {
  max-width: 90%;
  margin: 0 auto;
}

.customeasyimage-remove-button {
  // Similar to .course-promote {} in case it has an active state.
  display: block;
  padding: @global-margin-mini;
  line-height: @md-icon-size;
  text-align: center;
  border-radius: @mtx-course-el-actions-border-radius;
  transition: @global-hover-transition;
  z-index: 3;
  position: absolute;
  top: @global-margin;
  max-width: calc(@md-icon-size + @global-margin-mini * 2);

  [dir=ltr] &,
  [dir=rtl] [dir=ltr] & {
    right: @global-margin;
  }

  [dir=rtl] & {
    left: @global-margin;
    right: auto;
  }

  .customeasyimage-align-center ~ & {
    [dir=ltr] &,
    [dir=rtl] [dir=ltr] & {
      right: calc(5% + @global-margin);
    }

    [dir=rtl] & {
      left: calc(5% + @global-margin);
      right: auto;
    }
  }

  .customeasyimage-align-left ~ & {
    [dir=ltr] &,
    [dir=rtl] [dir=ltr] & {
      right: calc(50% + @global-margin);
    }

    [dir=rtl] & {
      left: calc(50% + @global-margin);
      right: auto;
    }
  }
}
