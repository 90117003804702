@page-sidebar-width: 320px;
@page-sidebar-closed-width: @page-sidebar-icon-size + (@page-sidebar-closed-padding-x * 2);
@page-sidebar-fixed-mode-width: 360px;
@page-sidebar-quick-toggler-pos-x: @global-margin-large;
@page-sidebar-padding-x: @global-margin-medium-2;
@page-sidebar-padding-y: @global-margin;
@page-sidebar-collapsible-padding-y: 1.125rem;
@page-sidebar-closed-padding-x: 1.25rem;
@page-sidebar-item-margin: @global-margin-mini;
@page-sidebar-icon-size: @md-icon-size;
@page-sidebar-icon-margin: @global-margin-large;
@page-sidebar-fixed-mode-icon-margin: @global-margin-medium;
@page-sidebar-icon-opacity: .5;
@page-sidebar-submenu-toggler-padding: @global-margin;
@page-sidebar-z-index: 1;
@page-sidebar-opened-z-index: @admin-navbar-z-index - 1;
@page-sidebar-box-shadow: 0 0 4px fade(@_black_color, 6%);
@page-sidebar-opened-box-shadow: 0 8px 8px fade(@_black_color, 6%);

.page-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 0;
  overflow: hidden;
  transition: width .1s ease;
  box-shadow: @page-sidebar-box-shadow;
  z-index: @page-sidebar-z-index;

  ul {
    .reset-list;
  }

  // Toggler.
  &-toggler {
    .md-icon {
      display: block;
    }
  }

  &-quick-toggler {
    &.page-quick-link {
      z-index: @page-sidebar-opened-z-index;
    }
  }

  // Group title.
  &-group-title {
    padding: @page-sidebar-padding-y @page-sidebar-padding-x;
  }

  // Menu.
  &-menu > li {
    .submenu-level-marked {
      & > .nav-item > .nav-link-wrapper > .nav-link {
        font-weight: @global-font-weight-bold;
      }
    }
  }

  &-menu > li.course-menu {
    @status-icon-size: .75rem;

    display: none;

    .nav-item[class*="nav-course-item-"] {
      & > .nav-link-wrapper {
        .nav-link:before {
          .material-icon();
          opacity: @page-sidebar-icon-opacity;
          // Reset .material-icon() styles.
          position: absolute;
          // Reset status icon styles.
          width: @page-sidebar-icon-size;
          height: @page-sidebar-icon-size;
          line-height: @page-sidebar-icon-size;
          border-radius: initial;
          background-color: transparent !important;
          margin-top: -(@page-sidebar-icon-size / 2);
          top: 50%;
          inset-inline-start: @page-sidebar-padding-x;
        }
      }

      &.nav-course-item-scorm-flag {
        & > .nav-link-wrapper .nav-link:before {
          content: '\e97a'; // md-account_tree
        }
      }

      &.nav-course-item-combi-flag {
        & > .nav-link-wrapper .nav-link,
        & > .nav-submenu > .nav-item > .nav-link-wrapper .nav-link {
          font-size: @global-font-size;
        }
      }

      &.nav-course-item-hidden {
        & > .nav-link-wrapper .nav-link:before {
          content: '\e8f5'; // md-visibility_off
        }
      }

      &.nav-course-item-restrict-date {
        & > .nav-link-wrapper .nav-link:before {
          content: '\e899'; // md-lock
        }
      }

      &.nav-course-item-restrict-h5p {
        & > .nav-link-wrapper .nav-link:before {
          content: '\e899'; // md-lock
        }
      }

      &.nav-course-item-restrict-video {
        & > .nav-link-wrapper .nav-link:before {
          content: '\e899'; // md-lock
        }
      }

      &.nav-course-item-restrict-page {
        & > .nav-link-wrapper .nav-link:before {
          content: '\e899'; // md-lock
        }
      }

      &.nav-course-item-recommended-for-job {
        & > .nav-link-wrapper .nav-link:before {
          content: '\e943'; // md-work
        }
      }

      &.nav-course-item-scorm-course {
        & > .nav-link-wrapper .nav-link:before {
          content: '\e834'; // md-check_box
        }
      }

      &.nav-course-item-external-link {
        & > .nav-link-wrapper .nav-link:before {
          content: '\e89e'; // md-open_in_new
        }
      }
    }

    .nav-item {
      & > .nav-link-wrapper.page-progress-wrapper {
        &:not(.page-progress-hidden-wrapper) {
          .nav-link:before {
            display: none !important;
          }
        }
      }

      .mtx-circular-progress {
        top: 50%;
        inset-inline-start: @page-sidebar-padding-x;
        margin-top: -(@mtx-circular-page-progress-size / 2);

        .md-icon {
          display: none;
          margin: 0 auto;
          color: inherit;
        }

        &.progress-completed {
          .md-icon {
            display: block;
            position: relative;
            top: auto;
            left: auto;
            right: auto;
            line-height: @mtx-circular-page-progress-size;
            font-size: @mtx-circular-page-progress-size * .75;

            .page-node-review & {
              display: none;
            }
          }
        }
      }
    }

    .nav-link {
      padding-inline-start: @page-sidebar-icon-size + (@page-sidebar-icon-margin / 2) + @page-sidebar-padding-x;
    }
  }

  .nav-item {
    &.open {
      .nav-item.open {
        margin-bottom: @global-margin-medium;

        .nav-item.open {
          margin-bottom: 0;
        }
      }

      & > .nav-link-wrapper {
        .nav-submenu-toggler:before {
          content: '\e5ce'; // md-expand_less
        }
      }
    }
  }

  .nav-link-wrapper {
    position: relative;
  }

  .nav-link {
    display: block;
    position: relative;
    text-decoration: none;
    transition: background-color .15s ease-in;

    &[disabled] {
      cursor: default;

      .title {
        opacity: .3;
      }
    }

    .md-icon {
      display: block;
    }

    .title {
      display: block;
      .break-word();
    }
  }

  // Submenu.
  .nav-submenu {
    display: none;
  }

  .nav-submenu-toggler {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    inset-inline-end: 0;
    padding: @page-sidebar-submenu-toggler-padding;
    line-height: 1;
    color: inherit;
    transition: background-color .15s ease-in;

    &:before {
      .material-icon();
      content: '\e5cf'; // md-expand_more
      top: 50%;
      transform: translateY(-50%);
    }
  }

  // Modes.
  &-default-mode & {
    &-quick-toggler.page-quick-link {
      bottom: @global-margin-large;
      inset-inline-end: @page-sidebar-quick-toggler-pos-x;
    }

    [role="navigation"] {
      padding-bottom: @global-margin-medium;
    }
  }

  &-fixed-mode & {
    &-menu {
      padding: @global-margin-medium 0;

      & > li.course-menu {
        font-size: @global-font-size-small;

        .nav-item[class*="nav-course-item-"] .nav-link:before {
          font-size: 1.25rem;
        }
      }

      & > li.course-anchors {
        font-size: @global-font-size-small;

        .nav-link {
          padding-inline: @page-sidebar-padding-x;
        }
      }
    }
  }

  // States.
  &-opened & {
    box-shadow: @page-sidebar-opened-box-shadow;
    z-index: @page-sidebar-opened-z-index;
    max-width: 100%;

    &-toggler {
      display: none;
    }

    &-menu {
      & > li + li {
        margin-top: 2.5rem;
      }

      & > li.course-menu {
        display: block;
      }
    }

    .nav-item {
      & + .nav-item {
        margin-top: @page-sidebar-item-margin;
      }
    }

    .nav-link {
      padding-block: @page-sidebar-padding-y;
      padding-inline: (@page-sidebar-icon-size + @page-sidebar-icon-margin + @page-sidebar-padding-x) @page-sidebar-submenu-toggler-padding;

      .md-icon {
        position: absolute;
        top: 50%;
        margin-top: -(@page-sidebar-icon-size / 2);
        inset-inline-start: @page-sidebar-padding-x;
      }

      &.nav-link-with-submenu {
        padding-inline-end: @page-sidebar-icon-size + @page-sidebar-padding-x;
      }
    }

    .nav-submenu {
      margin-top: @page-sidebar-item-margin;
    }

    .nav-submenu-toggler {
      display: block;
    }
  }

  &-opened&-default-mode & {
    width: @page-sidebar-width;

    &-quick-toggler {
      .md-icon:after {
        content: '\e5cd'; // md-close
      }
    }

    .mtx-sidebar-scroll:first-child {
      [role="navigation"] {
        padding-top: @global-margin-medium;
      }
    }

    &-quick-link-tooltip {
      display: none !important;
    }
  }

  &-opened&-fixed-mode & {
    width: @page-sidebar-fixed-mode-width;

    &-quick-toggler {
      .md-icon:after {
        [dir=ltr] & {
          content: '\e5cb'; // md-chevron_left
        }

        [dir=rtl] & {
          content: '\e5cc'; // md-chevron_right
        }
      }
    }
  }

  &-opened&-collapsible-menus & {
    @grouping-title-height: 2 * @page-sidebar-collapsible-padding-y + unit(@global-line-height, rem);

    &-menu,
    .mtx-sidebar-scroll,
    .mtx-sidebar-scroll > div {
      height: 100%;
      max-height: 100%;
    }

    &-menu {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0;

      & > li {
        overflow: hidden;
        height: @grouping-title-height;

        & > ul {
          height: calc(100% - @grouping-title-height);
          overflow-y: auto;
        }

        & + li {
          margin-top: 0;
          border-top: 1px solid fade(@_black_color, 6%);
        }

        &.menu-expanded {
          height: calc(100% - @grouping-title-height);
        }
      }
    }

    &-group-title {
      color: inherit;
      padding-block: @page-sidebar-collapsible-padding-y;
      padding-inline: @page-sidebar-padding-x @page-sidebar-submenu-toggler-padding;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &[aria-expanded="true"] {
        .md-icon {
          transform: rotate(180deg);
        }
      }
    }
  }

  &-opened&-collapsible-menus.collapsible-menus-initiated & {
    &-menu > li {
      transition: height 0.5s ease-in-out;

      & + li .page-sidebar-group-title {
        .md-icon:after {
          content: '\eacf'; // md-keyboard_double_arrow_up
        }
      }
    }
  }

  &-closed&-default-mode & {
    &-toggler {
      display: block;
      margin-top: @global-margin-medium;
    }
  }

  &-closed&-fixed-mode & {
    display: none;
  }

  // Media.
  @media (min-width: @breakpoint-medium) {
    &-fixed-mode & ~ .page-content-wrapper {
      transition: margin .1s linear;
    }

    &-opened&-fixed-mode {
      .page-quick-link[class*='page-sidebar'] {
        margin-inline-start: calc(@page-sidebar-fixed-mode-width + @page-sidebar-fixed-mode-icon-margin);
      }

      .page-container-sidebar-overlay {
        display: none;
      }

      .mtx-api-page-footer-nav-fixed {
        inset-inline-start: @page-sidebar-fixed-mode-width;
      }
    }

    &-opened&-fixed-mode & ~ .page-content-wrapper {
      margin-inline-start: @page-sidebar-fixed-mode-width;
    }
  }

  @media (min-width: @breakpoint-small) {
    &-default-mode & {
      &-quick-toggler {
        display: none;
      }
    }

    &-closed&-default-mode & {
      @quick-link-padding: @global-margin @page-sidebar-closed-padding-x;

      width: @page-sidebar-icon-size + (@page-sidebar-closed-padding-x * 2);

      &-toggler {
        padding: @quick-link-padding;
      }

      .nav-item {
        display: none;

        &.nav-item-quick-link {
          display: block;
        }
      }

      .nav-link {
        padding: @quick-link-padding;

        .title {
          display: none;
        }
      }

      &-menu,
      .nav-item {
        margin-top: @global-margin-small;
      }
    }

    &-default-mode & ~ .page-content-wrapper {
      margin-inline-start: @page-sidebar-closed-width;
    }
  }

  @media (max-width: @breakpoint-mini-max) {
    &-opened & {
      width: 100% !important;
    }

    &-closed&-default-mode & {
      display: none;
    }
  }

  @media (max-width: @breakpoint-small-max) {
    @toggler-padding: @global-margin-small;
    @toggler-pos-x: @page-sidebar-submenu-toggler-padding;

    &-group-title {
      padding-block: 0 @page-sidebar-padding-y;
      padding-inline: @page-sidebar-padding-x (@toggler-padding * 2 + @page-sidebar-icon-size + @toggler-pos-x * 2);
    }

    &-opened&-fixed-mode & {
      &-toggler {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-block: @page-sidebar-collapsible-padding-y;
        padding-inline: @page-sidebar-padding-x @page-sidebar-submenu-toggler-padding;

        .md-icon:after {
          content: '\e5cd'; // md-close
          color: inherit;
        }
      }
    }
  }
}

.masquerade-block-wrapper {
  display: none;
  margin: @global-margin-medium;

  // Form field.
  .container-inline {
    position: relative;
  }

  .form-item {
    display: block;
    margin: 0;
  }

  input {
    @submit-btn-size: 2rem;
    @submit-btn-padding: .5625rem;
    @submit-btn-margin: @global-margin-small;
    @text-field-height: unit((@submit-btn-padding * 2) + @global-line-height, rem);

    &[type=text] {
      height: @text-field-height;
      font-size: @global-font-size-small;
      padding-block: @submit-btn-padding;
      padding-inline: @global-margin-medium (@submit-btn-size + @submit-btn-margin * 2);
    }

    &.form-submit {
      .material-icon();
      .square(@submit-btn-size);
      .button-border-radius(@submit-btn-size);
      position: absolute;
      top: (@text-field-height - @submit-btn-size) / 2;
      inset-inline-end: @submit-btn-margin;
      border: 0 none;
      padding: 0;
      background-color: @page_content_background_color;
      color: @form-icon-color;
      transition: background-color .15s ease-in-out;
      outline: 0 none;
      appearance: none;
      line-height: normal;

      &:hover,
      &:focus {
        background-color: @_grey_lighten_color;
      }
    }
  }

  // Switch back.
  &.unmasquerade {
    margin: @global-margin-medium 0 @page-sidebar-item-margin;
  }

  .description {
    margin: 0;
    font-size: @global-font-size;
  }

  .switch-back {
    display: block;
    position: relative;
    padding-block: @page-sidebar-padding-y;
    padding-inline: (@page-sidebar-icon-size + @page-sidebar-icon-margin + @page-sidebar-padding-x) @page-sidebar-submenu-toggler-padding;

    .md-icon {
      position: absolute;
      top: 50%;
      inset-inline-start: @page-sidebar-padding-x;
      margin-top: -(@page-sidebar-icon-size / 2);
    }
  }

  .page-sidebar-opened & {
    display: block;
  }
}

.page-container-sidebar-overlay {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: @page-sidebar-opened-z-index - 1;

  .page-sidebar-opened & {
    display: block;
  }
}

// @Todo: Rework this w/o relation to sidebar menu.
.page-quick-link[class*='page-sidebar'] {
  z-index: @page-sidebar-opened-z-index;
  margin-inline-start: @global-margin-large;

  @media (min-width: @breakpoint-xxlarge) {
    margin-inline-start: @global-margin-medium * 2;
  }

  .md-icon {
    opacity: 1;
  }

  &.page-quick-link-dim {
    transition: transform .15s ease, @page-quick-link-dim-transition-opacity;

    &.inactive {
      transition: transform .15s ease, @page-quick-link-dim-transition-opacity-dimmed;
    }

    &:focus {
      opacity: 1 !important;
    }
  }
}

.page-sidebar-default-mode {
  // @Todo: Rework this w/o relation to sidebar menu.
  .page-sidebar-back-link {
    display: none;

    @media (min-width: @breakpoint-small) {
      margin-inline-start: calc(@page-sidebar-closed-width + @global-margin-large);
    }
  }

  &.page-sidebar-closed {
    .page-sidebar-back-link {
      display: block;
    }
  }
}

// Course menu tooltips.
.course-menu-nav-link-tooltip {
  .title,
  .list {
    & + .list {
      margin-top: @global-margin-medium;
    }
  }

  .item-title {
    margin-bottom: @global-margin-mini / 2;
  }

  .item-child {
    list-style-position: inside;
    font-weight: @global-font-weight-bold;

    &::marker {
      content: ' - ';
    }
  }
}

// Anchor links.
.mtx-course-anchor-link {
  .title {
    .text-ellipsis();
  }

  .copy-link {
    display: none;
    position: absolute;
    inset-inline-end: 0;
    top: 0;
    bottom: 0;
    padding: @page-sidebar-submenu-toggler-padding;
    transition-delay: .2s;

    .md-icon {
      color: inherit !important;
      position: static !important;
      margin-top: 0 !important;
    }

    &:hover,
    &:focus {
      opacity: .65;
    }

    &:focus-visible {
      opacity: 1;
    }
  }

  &:hover,
  &:focus-within {
    padding-inline-end: @page-sidebar-padding-x + @page-sidebar-icon-margin !important;

    .copy-link {
      display: block;
    }
  }
}
