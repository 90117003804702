#mtx-taxonomies {
  .mtx-api-item.vocabulary {
    width: 100%;
    margin-bottom: @global-margin-xlarge;

    .wrapper {
      padding: 0;
    }

    .title {
      font-size: 1.5rem;
    }

    .description {
      margin-top: @global-margin-medium;
    }

    &.parent {
      .icon {
        font-size: @md-icon-size;
      }
    }
  }

  .pager {
    margin: @global-margin-large 0 @global-margin-small;
  }
}

.mtx-vocabularies-form-modal {
  .form-translatable-field-with-accordion {
    & ~ .form-item-catalog-filter-status {
      margin-top: @global-margin-large;
    }
  }

  .form-item-availability {
    &,
    &:first-child {
      margin-top: @global-margin-medium * 2;
    }

    .form-item {
      margin-bottom: 0;

      &:first-child {
        margin-top: @form-item-margin-top;
      }
    }

    @media (min-width: @breakpoint-small) {
      .form-checkboxes {
        display: flex;
        flex-wrap: wrap;
        margin-inline-start: -@global-margin;
      }

      .form-item {
        width: 50%;
        padding-inline-start: @global-margin;
      }
    }
  }

  .image-preview img {
    max-width: 200px;
  }
}
