// @Todo: Rework these vars.
@datepicker-date-input-width: 9.938rem; // 159px
@datepicker-time-input-width: 5.625rem; // 90px
@datepicker-label-width: 5.625rem; // 90px

.mtx-date-field {
  &-block {
    .container-inline-date {
      & + .container-inline-date {
        & > .form-item {
          margin-top: @form-item-margin-top;
        }
      }

      & > .form-item {
        margin: 0;

        & > label {
          width: @datepicker-label-width;
          font-weight: @global-font-weight-semibold;

          [dir=ltr] & {
            float: left;
          }

          [dir=rtl] & {
            float: right;
          }

          @media all and (max-width: @breakpoint-small-max) {
            float: none;
            width: 100%;
          }
        }

        .date-padding {
          vertical-align: middle;

          label {
            display: none;
          }

          &,
          & > .form-item {
            float: none;
            display: inline-block;
          }
        }

        // Action icon.
        .field-suffix {
          display: inline-block;

          [dir=ltr] & {
            margin: 0 0 0 @global-margin-small;
          }

          [dir=rtl] & {
            margin: 0 @global-margin-small 0 0;
          }
        }
      }
    }
  }

  &-inline-block {
    & > * {
      display: inline-block;
      vertical-align: text-bottom;
      margin-bottom: @global-margin;
    }

    &.mtx-date-remove-margin {
      & > label,
      & > .form-item {
        margin: 0;
      }
    }

    .container-inline-date {
      & > .form-item {
        // Reset styles from date.css
        margin-right: 0;
      }

      .form-item input {
        // Override styles from date.css
        [dir=ltr] & {
          margin-right: @global-margin-small;
        }

        [dir=rtl] & {
          margin-right: 0;
          margin-left: @global-margin-small;
        }
      }

      @media all and (min-width: @breakpoint-small) {
        [dir=ltr] & {
          margin-right: @global-margin-medium;

          &:last-child {
            margin-right: 0;
          }
        }

        [dir=rtl] & {
          margin-left: @global-margin-medium;

          &:last-child {
            margin-left: 0;
          }
        }
      }

      @media all and (max-width: @breakpoint-mini-max) {
        [dir=ltr] & {
          .date-padding + .field-suffix {
            margin-right: @global-margin;
          }
        }

        [dir=rtl] & {
          .date-padding + .field-suffix {
            margin-left: @global-margin;
          }
        }
      }
    }

    &.mtx-date-field-container-inline-date-no-margin {
      .container-inline-date {
        @media all and (min-width: @breakpoint-small) {
          [dir=ltr] & {
            margin-right: 0;
          }

          [dir=rtl] & {
            margin-left: 0;
          }
        }
      }
    }
  }

  &-label-block {
    .container-inline-date > .form-item > label {
      display: block;
      width: auto;
      max-width: 100%;
    }
  }

  .container-inline-date {
    white-space: nowrap;

    .date-padding {
      .field-suffix {
        // Align with styles for .date-padding {} in date.css
        float: left;
      }

      [dir=rtl] & {
        .field-suffix {
          float: right;
        }
      }
    }

    .date-field-clear {
      // Fix for Safari (icon overlaps date fields).
      min-width: @md-icon-size;
      display: flex;
      margin-top: calc((@form-height - @md-icon-size) / 2);
    }

    [dir=rtl] & {
      // Overrides styles from date.css
      .form-item .form-item {
        float: right;
      }
    }
  }

  input {
    [dir=ltr] & {
      margin-right: @global-margin-small;

      &[type=checkbox] {
        margin-right: 0;
      }
    }

    [dir=rtl] & {
      margin-left: @global-margin-small;

      &[type=checkbox] {
        margin-left: 0;
      }
    }
  }

  // Action icon.
  .date-field-clear {
    .mtx-date-field-action();
  }

  .field-suffix {
    color: @_grey_dark_color; // .mtx-date-field-action()

    & > a {
      .mtx-date-field-action();
    }
  }
}

// @Todo: Improve in forms.
.mtx-date-field-action(@line-height: @form-height) {
  line-height: @line-height;
  color: @_grey_dark_color;
  transition: color .15s ease-in-out;

  &:hover,
  &:focus {
    color: @_grey_darken_color;

    .mtx-dark-theme & {
      color: lighten(@_grey_dark_color, 10%);
    }
  }

  &.disabled,
  &[disabled] {
    color: @_grey_color;
    cursor: default;

    .mtx-dark-theme & {
      color: lighten(@_grey_darken_color, 10%);
    }
  }
}

// Overwrites contrib "date" module styles (date.css).
.date-no-float {
  width: 100%;
}
