@mtx-feedback-question-margin: @global-margin-large-2;

// Feedback page/popup.
.mtx-feedback-page {
  & > .uk-container-center > * {
    @media all and (min-width: @breakpoint-large) {
      max-width: @utility-narrow-container-large-max-width * 1.25;
    }
  }
}

// Feedback questions.
.mtx-feedback-questions {
  label, .uk-form-label {
    margin-bottom: @global-margin-small;
  }

  .description {
    .mtx-text-editor-content {
      ul {
        padding-inline-start: @base-list-padding-left / 2;
      }

      a:not(:hover):not(:focus) {
        color: inherit;
      }
    }
  }

  & > .form-item,
  & > div[style],
  & > div[id*="ajax-wrapper"],
  .mtx-feedback-questions-group > .form-item,
  .mtx-feedback-questions-group > div[id*="states-field"],
  .mtx-feedback-question-type-text-block {
    margin: @mtx-feedback-question-margin 0;
  }

  .mtx-feedback-questions-group  > .form-item:first-child {
    margin-top: 0;
  }

  div[id*="ajax-wrapper"] .form-item {
    margin: 0;
  }

  .form-item + .description,
  div[id*="states-field"] + .description {
    // "Full name" field case.
    margin-top: @field-description-margin-top - @mtx-feedback-question-margin;
  }

  .mtx-api-rating-widget {
    justify-content: flex-start;
  }
}

.mtx-feedback-questions-ordered {
  counter-reset: question-item;

  & > .form-item,
  & > div[style],
  & > div[id*="ajax-wrapper"] {
    .ordered-list-item(question-item);

    &:before {
      font-weight: @global-font-weight-semibold; // The same as for .uk-form label {}
    }
  }
}

// Feedback question types.
.mtx-feedback-question-type-heading {
  margin: @mtx-feedback-question-margin * 2 0 @mtx-feedback-question-margin;
}

.mtx-feedback-question-type-divider-space > div {
  height: 1.875rem;
}

.mtx-feedback-question-type-divider-space,
.mtx-feedback-question-type-divider-line {
  padding: @global-margin-medium 0;
}

.mtx-feedback-question-type-divider-image {
  border-radius: @thumb-border-radius;
}

// Feedback questionnaire results.
.mtx-feedback-questionnaire-results-list-ordered {
  counter-reset: answer-item;

  .mtx-feedback-questionnaire-results-list-for-questions-group,
  & > .mtx-feedback-questionnaire-results-item.is-question {
    .ordered-list-item(answer-item);

    &:before {
      font-weight: bold; // The same as for .uk-text-bold {}
    }
  }
}

.mtx-feedback-questionnaire-results-item {
  &.is-question {
    margin: @mtx-feedback-question-margin 0;

    &:not(.is-file) .item-content {
      margin-top: @global-margin;
      padding: @global-margin @global-margin-large;
      border-radius: @button-border-radius;
    }
  }
}
