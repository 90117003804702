// Selection
.user-select(@select : none) {
  -webkit-user-select: @select;
  -moz-user-select: @select;
  user-select: @select;
}

// Placeholder
.placeholder(@rules) {
  &::-webkit-input-placeholder {
    @rules();
  }

  &:-moz-placeholder {
    @rules();
  }

  &::-moz-placeholder {
    @rules();
  }

  &:-ms-input-placeholder {
    @rules();
  }
}

// Shape
.square(@size: 50px) {
  display: block;
  width: @size;
  height: @size;
}

.circle(@size: 50px) {
  .square(@size);
  border-radius: 50%;
}

.triangle(@size: 5px, @color: black, @direction: top) {
  border: @size solid transparent;
  display: block;
  width: 0;
  height: 0;

  & when (@direction = top) {
    border-top-width: 0;
    border-bottom-color: @color;
  }

  & when (@direction = bottom) {
    border-bottom-width: 0;
    border-top-color: @color;
  }

  & when (@direction = left) {
    border-left-width: 0;
    border-right-color: @color;
  }

  & when (@direction = right) {
    border-right-width: 0;
    border-left-color: @color;
  }
}

// Button
.button-toggler(@pos-y: @button-padding-horizontal / 2) {
  position: relative;

  &:after {
    .material-icon();
    content: '\e5cf'; // md-expand_more
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .uk-open&:after,
  &.uk-open:after {
    content: '\e5ce'; // md-expand_less
  }

  &.disabled,
  &:disabled,
  &[disabled] {
    pointer-events: none;
    cursor: default;

    &:after {
      content: '\e5cf' !important; // md-expand_more
    }
  }

  [dir=ltr] & {
    padding-right: @pos-y + @md-icon-size + @global-margin-small;

    &:after {
      right: @pos-y;
    }
  }

  [dir=rtl] & {
    padding-left: @pos-y + @md-icon-size + @global-margin-small;

    &:after {
      left: @pos-y;
    }
  }
}

.button-border-radius(@size: 2.5rem, @fs: 16, @diff: 0px) {
  // Value in px w/o unit for calc()
  @height: unit(@size * @fs, px);
  @button-height-px: unit(@button-height * @fs, px);

  border-radius: calc((@height / @button-border-radius-ratio) + @diff);
}

// Icon
.fa-icon() {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  color: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.material-icon(@font-size: @md-icon-size) {
  display: inline-block;
  font: normal normal normal @font-size / 1 "Material Symbols";
  font-display: block;
  color: inherit;
  width: 1em;
  overflow: hidden;
  letter-spacing: normal;
  text-rendering: optimizeLegibility;
  text-transform: none;
  user-select: none;
  white-space: nowrap;
  word-wrap: normal;
  position: relative;
  vertical-align: middle;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: grayscale;
  font-variation-settings:
    'FILL' var(--icons-fill, 0),
    'wght' var(--icons-weight, 300),
    'GRAD' var(--icons-grade, 0),
    'opsz' var(--icons-optical-size, 24)
}

.input-icon(@content) {
  position: relative;

  &:before {
    .material-icon(@form-icon-font-size);
    color: @form-icon-color;
    content: '\@{content}';
    line-height: unit(@form-height, rem);
    position: absolute;
    bottom: 0;
    text-align: center;
    width: @form-icon-width;
  }

  input {
    [dir=ltr] & {
      padding-left: @form-icon-width;
    }

    [dir=rtl] & {
      padding-right: @form-icon-width;
    }
  }
}

// @Todo: Remove.
.input-date-icon() {
  .input-icon('ebcc'); // md-calendar_month
}

.input-search-icon() {
  .input-icon('e8b6'); // md-search
}

.input-video-icon() {
  .input-icon('e04b'); // md-videocam
}

.icon-link(@bg, @icon) {
  a {
    background: @bg;

    &:after {
      content: '\@{icon}';
    }
  }
}

.input-date-icon-background-image {
  // md-calendar_month
  @svg: escape('<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="@{form-icon-color}"><g><rect fill="none" height="24" width="24"/></g><g><path d="M19,4h-1V2h-2v2H8V2H6v2H5C3.89,4,3.01,4.9,3.01,6L3,20c0,1.1,0.89,2,2,2h14c1.1,0,2-0.9,2-2V6C21,4.9,20.1,4,19,4z M19,20 H5V10h14V20z M19,8H5V6h14V8z M9,14H7v-2h2V14z M13,14h-2v-2h2V14z M17,14h-2v-2h2V14z M9,18H7v-2h2V18z M13,18h-2v-2h2V18z M17,18 h-2v-2h2V18z"/></g></svg>');

  /*
      1. Selectors specificity and current file position
      allows us to override default/focus states styles for this field.
      See contrib/uikit/src/less/core/form.less

      2. Change readonly to any other attribute common for all inputs,
      in case field_user_birthday, that is only one editable datepicker for now,
      will require an icon to be displayed.
   */

  &[type='text'][readonly] {
    width: @datepicker-date-input-width;
    background-image: url('data:image/svg+xml,@{svg}');
    background-size: @md-icon-size @md-icon-size;
    background-repeat: no-repeat;

    [dir=ltr] &,
    [dir=rtl] [dir=ltr] & {
      padding-right: @form-padding-x;
      padding-left: @form-icon-width;
      background-position: calc((@md-icon-size / 2) - @form-border-width) calc((@md-icon-size / 2) - @form-border-width);
    }

    [dir=rtl] & {
      padding-left: @form-padding-x;
      padding-right: @form-icon-width;
      background-position: calc(100% - (@md-icon-size / 2) - @form-border-width) calc((@md-icon-size / 2) - @form-border-width);
    }
  }
}

// List
.reset-list() {
  margin: 0;
  padding: 0;
  list-style: none;
  background-image: none;
}

.list-marker-disc(@size: .75rem, @line-height: 1.375, @padding: @global-margin-medium) {
  position: relative;

  &:before {
    content: '';
    .circle(@size);
    position: absolute;
    top: unit((@line-height - @size) / 2, rem);
    background-color: @_grey_color;
  }

  [dir=ltr] & {
    padding-left: @size + @padding;

    &:before {
      left: 0;
    }
  }

  [dir=rtl] & {
    padding-right: @size + @padding;

    &:before {
      right: 0;
    }
  }
}

.ordered-list-item(@counter: list-item) {
  position: relative;
  counter-increment: @counter;
  padding-inline-start: 3ch;

  &:before {
    position: absolute;
    inset-inline-start: 0;
    content: counter(@counter)'.';
  }
}

// Text
.text-ellipsis() {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-ellipsis-box(@rows: 1, @line-height: @global-line-height) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box !important;
  line-height: @line-height;
  -webkit-line-clamp: @rows;
  -moz-line-clamp: @rows;
  -ms-line-clamp: @rows;
  -o-line-clamp: @rows;
  line-clamp: @rows;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -ms-box-orient: vertical;
  -o-box-orient: vertical;
  box-orient: vertical;
  word-break: break-word;
  word-wrap: break-word;

  // In Safari there is an issue for container in case it has more than 1 not inline children.
  // max-height is not an option due to CR-4572.
  &.mtx-text-editor-content > * {
    display: inline;
  }
}

.hyphens(@type: auto) {
  -webkit-hyphens: @type;
  -moz-hyphens: @type;
  -ms-hyphens: @type;
  hyphens: @type;
}

.break-word() {
  word-break: break-word;
  word-wrap: break-word;
  .hyphens();
}

// Spinner
.loading(@spinner-size, @spinner-bg-color: fade(@global-text-color, 20%)) {
  background: @spinner-bg-color;
  border-radius: @global-border-radius;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 999;

  & > .fa {
    bottom: 0;
    color: @global-link-color;
    font-size: @spinner-size;
    height: @spinner-size;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: @spinner-size;
    z-index: 2;
  }
}

// Misc
.blur(@radius: 3px) {
  -webkit-filter: blur(@radius);
  filter: blur(@radius);
  -ms-filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='3');
}

// Position
.position-cover(@position: absolute) {
  position: @position;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.position-center(@position: absolute) {
  position: @position;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.position-top(@top: 0) {
  position: absolute;
  top: @top;
  left: 0;
  right: 0;
}

.position-bottom(@bottom: 0) {
  position: absolute;
  bottom: @bottom;
  left: 0;
  right: 0;
}

// Form.
.form-field-with-sign(@field-selector: ~'', @field-raw-width: @field-type-number-width, @field-sign: '%', @field-sign-width: @field-type-number-percent-sign-width) {
  @{field-selector} {
    position: relative;

    input {
      .set-width();

      .set-width() when (isnumber(@field-raw-width)) {
        width: @field-raw-width + @field-sign-width;
        padding-inline-end: @field-sign-width;
      }
      .set-width() when not (isnumber(@field-raw-width)) {
        padding-inline-end: calc(@field-type-number-percent-sign-width + @global-margin-mini * 2);
      }
    }

    &:after {
      content: @field-sign;
      position: absolute;
      bottom: 0;
      width: @field-sign-width;
      line-height: @form-height;
      text-align: center;
      .set-position();

      .set-position() when (isnumber(@field-raw-width)) {
        inset-inline-start: @field-raw-width;
      }
      .set-position() when not (isnumber(@field-raw-width)) {
        inset-inline-end: @global-margin-mini;
      }
    }
  }
}

.form-type-select-max-width(@size: 0) {
  width: 100%;
  max-width: calc(100% - @size);
}

//** Focus state
@focus-state-box-shadow-size: 4px;

.focus-state-box-shadow(@inset: false, @inverted: false) when (@inset) and (@inverted) {
  box-shadow: inset 0 0 0 @focus-state-box-shadow-size @_white_color;
  transition: box-shadow .12s ease-out;
}

.focus-state-box-shadow(@inset: false, @inverted: false) when (@inset) and not (@inverted) {
  box-shadow: inset 0 0 0 @focus-state-box-shadow-size fade(@focus_indicator_color, 80%);
  transition: box-shadow .12s ease-out;
}

.focus-state-box-shadow(@inset: false, @inverted: false) when not (@inset) and (@inverted) {
  box-shadow: 0 0 0 @focus-state-box-shadow-size @_white_color;
  transition: box-shadow .12s ease-out;
}

.focus-state-box-shadow(@inset: false, @inverted: false) when not (@inset) and not (@inverted) {
  box-shadow: 0 0 0 @focus-state-box-shadow-size fade(@focus_indicator_color, 80%);
  transition: box-shadow .12s ease-out;
}
