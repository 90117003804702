//
// Component: Form
//
// ========================================================================


// Variables
// ========================================================================

@form-height:                                   @global-height;
@form-padding-x:                                @global-margin-medium;
@form-padding-y:                                @global-margin;
@form-padding:                                  @form-padding-y @form-padding-x;
@form-border:                                   @_grey_dark_color;
@form-border-width:                             1px;
@form-border-transition:                        border-color .15s ease-in-out;
@form-background:                               transparent;
@form-color:                                    inherit;

@form-font-family:                              inherit;
@form-font-size:                                @global-font-size;

@form-focus-border:                             var(--form-focus-border-color);
@form-focus-background:                         @form-background;
@form-focus-color:                              inherit;

@form-disabled-border:                          #ddd;
@form-disabled-background:                      #f5f5f5;
@form-disabled-color:                           @global-muted-color;

@form-placeholder-color:                        @global-placeholder-color;

@form-gutter:                                   15px;

@form-legend-border:                            #ddd;
@form-legend-border-width:                      1px;
@form-legend-font-size:                         18px;
@form-legend-line-height:                       30px;

@form-small-height:                             2.5rem;
@form-large-height:                             40px;
@form-small-padding:                            @global-margin-small @global-margin;
@form-large-padding:                            8px 6px;
@form-small-font-size:                          inherit;
@form-large-font-size:                          16px;

@form-danger-border:                            @text-danger-color;
@form-danger-background:                        @form-background;
@form-danger-color:                             @form-color;

@form-success-border:                           #8ec73b;
@form-success-background:                       @form-background;
@form-success-color:                            @form-color;

@form-blank-border:                             #ddd;
@form-blank-border-style:                       dashed;

@form-mini-width:                               40px;
@form-small-width:                              130px;
@form-medium-width:                             200px;
@form-large-width:                              500px;

@form-help-inline-margin-left:                  10px;
@form-help-block-margin-top:                    5px;

@form-controls-condensed-margin-vertical:       5px;

@form-stacked-margin-bottom:                    0;
@form-stacked-font-weight:                      @global-font-weight-semibold;

@form-horizontal-label-width:                   200px;
@form-horizontal-label-margin-top:              5px;
@form-horizontal-controls-margin-left:          215px;
@form-horizontal-controls-text-padding-top:     5px;

@form-icon-width:                               @md-icon-size + @form-padding-x + @global-margin-small;
@form-icon-font-size:                           @md-icon-size;
@form-icon-color:                               @_grey_dark_color;

@form-search-field-height:                      3.5rem;
@form-search-field-button-size:                 @md-icon-size + @global-margin;
@form-search-field-button-background:           @_grey_lighten_color;


// Component
// ========================================================================

.hook-form() {
  font-family: @form-font-family;
  font-size: @form-font-size;
  border-radius: @inputs-border-radius;
  word-break: break-word;
  word-wrap: break-word;
  transition: @form-border-transition;

  &.error {
    &, &:focus {
      border-color: @form-danger-border;
    }
  }
}

// Focus state
.hook-form-focus() {}

// Disabled state
.hook-form-disabled() {
  .mtx-dark-theme & {
    background-color: @overlay-color-lighten;
  }
}

// Legend
.hook-form-legend() {
  font-size: @base-h3-font-size;
}


// Validation states
// ========================================================================

//
// Error state
//

.hook-form-danger() {}

//
// Success state
//

.hook-form-success() {}


// Style modifiers
// ========================================================================

.hook-form-blank() {}

.hook-form-blank-focus() {}


// Sub-object: `uk-form-label`
// ========================================================================

.hook-form-stacked-label() {}

.hook-form-horizontal-label() {
  [dir=rtl] & {
    float: right;
  }
}

// Miscellaneous
// ========================================================================

.hook-form-misc() {
  :root {
    --form-focus-border-color: @_black_color;

    .mtx-dark-theme {
      --form-focus-border-color: @_white_color;
    }
  }

  .uk-form {
    font-family: @form-font-family;
    font-size: @form-font-size;

    .edit-form & {
      font-size: inherit;
    }

    label,
    .uk-form-label {
      display: block;
      font-weight: @global-font-weight-semibold;
    }

    .description {
      margin-top: @field-description-margin-top;
      font-size: @field-description-font-size;

      code {
        font-size: @base-code-font-size;
      }
    }

    label.option,
    .form-type-checkbox label,
    .form-radios label {
      display: inline;
      font-weight: @global-font-weight-normal;
    }

    .form-actions {
      margin-top: (@global-margin-medium * 2) - @button-group-margin-y;
      margin-bottom: @global-margin;
    }

    select {
      white-space: nowrap;

      &[multiple],
      &[size] {
        height: @form-height;
      }
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }
  }

  .uk-form-icon {
    & > [class*='uk-icon-'],
    & > [class*='fa-'] {
      top: 30%;
    }

    & > .md-icon {
      position: absolute;
      top: 0;
      line-height: @form-height;
      color: @form-icon-color;
      text-align: center;
      pointer-events: none;

      [dir=ltr] &,
      [dir=rtl] [dir=ltr] & {
        left: @form-padding-x;
      }

      [dir=rtl] & {
        right: @form-padding-x;
      }
    }

    &:not(.uk-form-icon-flip) input {
      [dir=ltr] &,
      [dir=rtl] [dir=ltr] & {
        padding-left: @form-icon-width !important;
        padding-right: @form-padding-x !important;
      }

      [dir=rtl] & {
        padding-left: @form-padding-x !important;
        padding-right: @form-icon-width !important;
      }
    }
  }

  select:not([multiple]):not([size]) {
    // md-unfold_more
    @svg: escape('<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="@{form-icon-color}"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 5.83L15.17 9l1.41-1.41L12 3 7.41 7.59 8.83 9 12 5.83zm0 12.34L8.83 15l-1.41 1.41L12 21l4.59-4.59L15.17 15 12 18.17z"/></svg>');

    appearance: none;
    background-image: url('data:image/svg+xml;charset=UTF-8,@{svg}');
    background-size: @md-icon-size @md-icon-size;
    background-repeat: no-repeat;

    &.chosen-enable,
    // It's invisible element.
    // See contrib/uikit/src/less/components/form-select.less
    .uk-form-select & {
      background-image: none;
    }

    &::-ms-expand {
      display: none;
    }

    [dir=ltr] &,
    [dir=rtl] [dir=ltr] & {
      padding-left: @form-padding-x;
      padding-right: @md-icon-size;
      background-position: calc(100% - @form-border-width) calc((@md-icon-size / 2) - @form-border-width);
    }

    [dir=rtl] & {
      padding-right: @form-padding-x;
      padding-left: @md-icon-size;
      background-position: @form-border-width calc((@md-icon-size / 2) - @form-border-width);
    }
  }

  // system.theme.css
  .form-required {
    color: inherit;
    margin-inline-start: -.1875em;
  }

  // Add: `uk-form-search-field`
  .uk-form-search-field {
    .form-item-search input {
      height: @form-search-field-height;
      padding: @global-margin-medium @form-padding-x;
      padding-inline-start: @form-padding-x + @md-icon-size + @global-margin;
    }
  }

  // Add: `uk-form-search-field-with-icon`
  .uk-form-search-field-with-icon {
    .form-item-search {
      .input-search-icon;
    }

    .form-item-search:before {
      line-height: @form-search-field-height;
    }
  }

  // Add: `uk-form-search-field-with-button`
  .uk-form-search-field-with-button {
    .uk-form-search-field-button {
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background-color: transparent;
      color: @_grey_dark_color;
      transition: background-color .15s ease-in-out;
      inset-inline-start: @form-padding-x;

      &[disabled] {
        cursor: text;
      }

      .md-icon {
        line-height: @form-search-field-button-size;
      }
    }

    .form-item-search.is-focused,
    .form-item-search.is-filled {
      .uk-form-search-field-button {
        inset-inline-start: auto;
        inset-inline-end: @form-padding-x;
        text-align: center;
        .square(@form-search-field-button-size);
        .button-border-radius(@form-search-field-button-size);

        &:hover,
        &:focus {
          background-color: @form-search-field-button-background;
        }
      }

      input {
        padding-inline-start: @form-padding-x;
        padding-inline-end: @form-padding-x + @form-search-field-button-size + @global-margin;
      }
    }

    .uk-touch & .form-item-search {
      &.is-focused,
      &.is-filled {
        .uk-form-search-field-button {
          background-color: @form-search-field-button-background;
        }
      }
    }
  }

  @media (min-width: @breakpoint-large) {
    .uk-form-horizontal .uk-form-controls {
      [dir=ltr] &,
      [dir=rtl] [dir=ltr] & {
        margin-right: 0;
      }

      [dir=rtl] & {
        margin-left: 0;
        float: right;
      }
    }
  }
}
