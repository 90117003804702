.enrollment-requests-course {
  .mtx-thumb {
    &,
    &-image {
      border-radius: @thumb-border-radius;
    }
  }
}

.enrollment-requests-item {
  @media all and (max-width: @breakpoint-small-max) {
    .mtx-button-group {
      text-align: initial !important;
    }
  }
}
