.mtx-api-info-box {
  border-radius: @modal-dialog-border-radius;
  padding: @global-margin-large;

  p:last-child {
    margin-bottom: 0;
  }

  &.mtx-api-info-box-medium {
    padding: @global-margin @global-margin-medium;
  }
}

.mtx-api-primary-info-box {
  @bg-color: tint(@global-primary-background, 90%);

  background-color: @bg-color;
  color: contrast(@bg-color, @_black_color, @_white_color);

  .md-icon:not(.regular) {
    color: @text-primary-color;
  }
}

.mtx-api-warning-info-box {
  @bg-color: lighten(@global-warning-background, 37%);

  background-color: @bg-color;
  color: contrast(@bg-color, @_black_color, @_white_color);

  .md-icon {
    color: @text-warning-color;
  }
}
