.mtx-general-settings-form {
  .social-settings-block {
    input[disabled] + label {
      color: @global-muted-color;
    }
  }

  .form-item-functionality-mtx-teams-widget-enabled {
    display: none;
  }

  .form-item-watermark-material-watermark-text[style*="display: none"] + .token-click-insert {
    display: none;
  }
}
