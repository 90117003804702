//
// Component: Utility
//
// ========================================================================


// Variables
// ========================================================================

@utility-container-padding-horizontal:          @global-grid-gutter;
@utility-container-large-max-width:             1260px;
@utility-container-large-padding-horizontal:    1.875rem;

@utility-narrow-container-medium-max-width:    75%;
@utility-narrow-container-large-max-width:     648px;

@utility-align-horizontal:                      @global-margin-small;
@utility-align-vertical:                        @global-margin;

@utility-margin:                                @global-margin;
@utility-margin-mini:                           @global-margin-mini;
@utility-margin-small:                          @global-margin-small;
@utility-margin-medium:                         @global-margin-medium;
@utility-margin-medium-2:                       @global-margin-medium-2;
@utility-margin-large:                          @global-margin-large;
@utility-margin-large-2:                        @global-margin-large-2;
@utility-margin-xlarge:                         @global-margin-xlarge;

@utility-heading-large-small-font-size:         @global-font-size * 2.6;
@utility-heading-large-small-line-height:       @utility-heading-large-small-font-size * 1.154;
@utility-heading-large-font-size:               @global-font-size * 3.72;
@utility-heading-large-line-height:             @utility-heading-large-font-size * 1.23;

@utility-link-muted-color:                      @global-muted-color;
@utility-link-muted-hover-color:                @global-link-hover-color;

@utility-scrollable-box-border:                 @global-border;


// Container
// ========================================================================

.hook-container() {
  .page-sidebar-menu-expand-by-default .page-content-layout & {
    @media (min-width: @breakpoint-medium) and (max-width: @breakpoint-large-max) and (orientation: landscape), (min-width: @breakpoint-xlarge) {
      @utility-container-large-padding-horizontal: 6rem; // 96px

      padding: 0 @utility-container-large-padding-horizontal;
      max-width: none;
    }

    @media (min-width: @breakpoint-xxlarge) {
      @utility-container-large-max-width: 1200px;
      @utility-container-large-padding-horizontal: 7.5rem; // 120px

      padding: 0 @utility-container-large-padding-horizontal;
      max-width: calc(@utility-container-large-max-width + @utility-container-large-padding-horizontal * 2);
    }
  }

  &.uk-container-small {
    @utility-container-small-max-width: 50rem;

    max-width: @utility-container-small-max-width + 2 * @utility-container-padding-horizontal;

    /* Large screen and bigger */
    @media (min-width: @breakpoint-xlarge) {
      max-width: @utility-container-small-max-width + 2 * @utility-container-large-padding-horizontal;
    }
  }
}


// Scrollable
// ========================================================================

.hook-scrollable-box() {}


// Miscellaneous
// ========================================================================

.hook-utility-misc() {
  .uk-reset-list {
    .reset-list;
  }

  /* Border
 ========================================================================== */

  .uk-border-radius-none {
    border-radius: 0 !important;
  }

  /* Margin
 ========================================================================== */

  /*
   * Mini margins
   */
  .uk-margin-mini { margin-bottom: @utility-margin-mini; }
  * + .uk-margin-mini { margin-top: @utility-margin-mini; }

  .uk-margin-mini-top {
    margin-top: @utility-margin-mini !important;
  }

  .uk-margin-mini-bottom {
    margin-bottom: @utility-margin-mini !important;
  }

  /*
   * Medium margins
   */
  .uk-margin-medium { margin-bottom: @utility-margin-medium; }
  * + .uk-margin-medium { margin-top: @utility-margin-medium; }

  .uk-margin-medium-top {
    margin-top: @utility-margin-medium !important;
  }

  .uk-margin-medium-bottom {
    margin-bottom: @utility-margin-medium !important;
  }

  /*
   * Medium-2 margins
   */
  .uk-margin-medium-2 { margin-bottom: @utility-margin-medium-2; }
  * + .uk-margin-medium-2 { margin-top: @utility-margin-medium-2; }

  .uk-margin-medium-2-top {
    margin-top: @utility-margin-medium-2 !important;
  }

  .uk-margin-medium-2-bottom {
    margin-bottom: @utility-margin-medium-2 !important;
  }

  /*
   * Large-2 margins
   */
  .uk-margin-large-2 { margin-bottom: @utility-margin-large-2; }
  * + .uk-margin-large-2 { margin-top: @utility-margin-large-2; }

  .uk-margin-large-2-top {
    margin-top: @utility-margin-large-2 !important;
  }

  .uk-margin-large-2-bottom {
    margin-bottom: @utility-margin-large-2 !important;
  }

  /*
   * Largest margins
   */

  .uk-margin-xlarge {
    margin-bottom: @utility-margin-xlarge;
  }

  * + .uk-margin-xlarge {
    margin-top: @utility-margin-xlarge;
  }

  .uk-margin-xlarge-top {
    margin-top: @utility-margin-xlarge !important;
  }

  .uk-margin-xlarge-bottom {
    margin-bottom: @utility-margin-xlarge !important;
  }

  .uk-margin-xlarge-left {
    margin-left: @utility-margin-xlarge !important;
  }

  .uk-margin-xlarge-right {
    margin-right: @utility-margin-xlarge !important;
  }

  /*
   * Margin remove
   */
  .uk-margin-vertical-remove {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  /*
   * Floats
   */
  [dir=ltr],
  [dir=rtl] [dir=ltr] {
    .uk-float-right {
      float: right;
    }

    .uk-float-left {
      float: left;
    }

    .uk-align-left {
      margin-left: 0;
      margin-right: @utility-align-horizontal;
      float: left;
    }

    .uk-align-right {
      margin-right: 0;
      margin-left: @utility-align-horizontal;
      float: right;
    }

    .uk-margin-left {
      margin-left: @utility-margin;
    }

    .uk-margin-right {
      margin-right: @utility-margin;
    }

    .uk-margin-mini-left {
      margin-left: @utility-margin-mini;
    }

    .uk-margin-mini-right {
      margin-right: @utility-margin-mini;
    }

    .uk-margin-small-left {
      margin-left: @utility-margin-small;
    }

    .uk-margin-small-right {
      margin-right: @utility-margin-small;
    }

    .uk-margin-medium-left {
      margin-left: @utility-margin-medium;
    }

    .uk-margin-medium-right {
      margin-right: @utility-margin-medium;
    }

    .uk-margin-large-right {
      margin-right: @utility-margin-large;
    }

    .uk-margin-xlarge-left {
      margin-left: @utility-margin-xlarge !important;
    }

    .uk-margin-xlarge-right {
      margin-right: @utility-margin-xlarge !important;
    }

    .uk-position-bottom-right,
    .uk-position-top-right {
      right: 0;
      left: auto;
    }

    /* Tablet and bigger */
    @media (min-width: @breakpoint-medium) {
      .uk-align-medium-left {
        margin-left: 0;
        margin-right: @utility-align-horizontal;
        float: left;
      }

      .uk-align-medium-right {
        margin-right: 0;
        margin-left: @utility-align-horizontal;
        float: right;
      }
    }
  }

  [dir=rtl] {
    .uk-float-right {
      float: left;
    }

    .uk-float-left {
      float: right;
    }

    .uk-align-left {
      margin-right: 0;
      margin-left: @utility-align-horizontal;
      float: right;
    }

    .uk-align-right {
      margin-left: 0;
      margin-right: @utility-align-horizontal;
      float: left;
    }

    .uk-margin-left {
      margin-left: 0 !important;
      margin-right: @utility-margin;
    }

    .uk-margin-right {
      margin-right: 0 !important;
      margin-left: @utility-margin;
    }

    .uk-margin-mini-left {
      margin-left: 0 !important;
      margin-right: @utility-margin-mini;
    }

    .uk-margin-mini-right {
      margin-right: 0 !important;
      margin-left: @utility-margin-mini;
    }

    .uk-margin-small-left {
      margin-left: 0 !important;
      margin-right: @utility-margin-small;
    }

    .uk-margin-small-right {
      margin-right: 0 !important;
      margin-left: @utility-margin-small;
    }

    .uk-margin-medium-left {
      margin-right: @utility-margin-medium;
    }

    .uk-margin-medium-right {
      margin-right: 0 !important;
      margin-left: @utility-margin-medium;
    }

    .uk-margin-large-right {
      margin-right: 0 !important;
      margin-left: @utility-margin-large;
    }

    .uk-margin-xlarge-left {
      margin-right: @utility-margin-xlarge !important;
    }

    .uk-margin-xlarge-right {
      margin-left: @utility-margin-xlarge !important;
    }

    .uk-position-bottom-right,
    .uk-position-top-right {
      left: 0;
      right: auto;
    }

    /* Tablet and bigger */
    @media (min-width: @breakpoint-medium) {
      .uk-align-medium-left {
        margin-right: 0;
        margin-left: @utility-align-horizontal;
        float: right;
      }

      .uk-align-medium-right {
        margin-left: 0;
        margin-right: @utility-align-horizontal;
        float: left;
      }
    }
  }

  @media all and (min-width: @breakpoint-small) {
    .uk-visible-xsmall {
      display: none !important;
    }
  }

  @media all and (max-width: @breakpoint-mini-max) {
    .uk-hidden-xsmall {
      display: none !important;
    }
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
    -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
    border: 0;
    white-space: nowrap;
  }

  .sr-only-focusable {
    &:active,
    &:focus {
      position: static;
      width: auto;
      height: auto;
      margin: 0;
      overflow: visible;
      clip: auto;
    }
  }

  // Add: `uk-gap`
  //      `uk-row-gap`
  //      `uk-column-gap`
  .uk-gap {
    gap: @global-margin;
  }

  .uk-row-gap {
    row-gap: @global-margin;
  }

  .uk-column-gap {
    column-gap: @global-margin;
  }

  // Add: `uk-gap-mini`
  //      `uk-row-gap-mini`
  //      `uk-column-gap-mini`
  .uk-gap-mini {
    gap: @global-margin-mini;
  }

  .uk-row-gap-mini {
    row-gap: @global-margin-mini;
  }

  .uk-column-gap-mini {
    column-gap: @global-margin-mini;
  }

  // Add: `uk-gap-small`
  //      `uk-row-gap-small`
  //      `uk-column-gap-small`
  .uk-gap-small {
    gap: @global-margin-small;
  }

  .uk-row-gap-small {
    row-gap: @global-margin-small;
  }

  .uk-column-gap-small {
    column-gap: @global-margin-small;
  }

  // Add: `uk-gap-medium`
  //      `uk-row-gap-medium`
  //      `uk-column-gap-medium`
  .uk-gap-medium {
    gap: @global-margin-medium;
  }

  .uk-row-gap-medium {
    row-gap: @global-margin-medium;
  }

  .uk-column-gap-medium {
    column-gap: @global-margin-medium;
  }

  // Add: `uk-gap-large`
  //      `uk-row-gap-large`
  //      `uk-column-gap-large`
  .uk-gap-large {
    gap: @global-margin-large;
  }

  .uk-row-gap-large {
    row-gap: @global-margin-large;
  }

  .uk-column-gap-large {
    column-gap: @global-margin-large;
  }

  // Add: `uk-overflow-wrap-anywhere`
  .uk-overflow-wrap-anywhere {
    overflow-wrap: anywhere;
  }

  // Add: `uk-cursor-default`
  .uk-cursor-default {
    cursor: default;

    &.uk-button:not(:disabled) {
      cursor: default;
    }
  }
}
