:root {
  // Overrides generic sizes.
  --ck-font-face: inherit;
  --ck-line-height-base: @global-line-height;
  --ck-font-size-base: @global-font-size;
  --ck-font-size-tiny: @global-font-size-xsmall;
  --ck-font-size-small: @global-font-size-small;
  --ck-font-size-normal: @global-font-size;
  --ck-font-size-big: @global-font-size-large;
  --ck-font-size-large: @global-font-size-xlarge;
  --ck-border-radius: @inputs-border-radius;
  --ck-ui-component-min-height: 2rem;
  --ck-inner-shadow: 0 none;
  --ck-spacing-unit: .75rem;

  // Helper variables to avoid duplication in the colors.
  --ck-custom-background: transparent;
  --ck-custom-foreground: transparent;
  --ck-custom-border: @form-border;
  --ck-custom-text: inherit;

  // Overrides generic colors.
  --ck-color-base-background: var(--ck-custom-background);
  --ck-color-base-foreground: var(--ck-custom-background);
  --ck-color-base-border: var(--ck-custom-border);
  --ck-color-focus-border: @form-focus-border;
  --ck-color-base-text: var(--ck-custom-text);
  --ck-color-engine-placeholder-text: @global-placeholder-color;
  --ck-color-button-default-background: var(--ck-custom-background);
  --ck-color-button-save: @text-success-color;
  --ck-color-button-cancel: @text-danger-color;
  --ck-color-toolbar-background: var(--ck-custom-background);
  --ck-color-toolbar-border: var(--ck-custom-border);
  --ck-color-dropdown-panel-background: @dropdown-background;
  --ck-tooltip-text-padding: @tooltip-padding-horizontal;
}

// Overrides styles.
.ck.ck-input {
  &,
  & + .ck-label {
    font-size: @global-font-size-small;
  }
}

.ck.ck-balloon-panel {
  --ck-color-labeled-field-label-background: @dropdown-background;

  background: @dropdown-background;
  color: @dropdown-color;

  &.ck-tooltip {
    padding: @tooltip-padding-vertical @tooltip-padding-horizontal;

    .ck-tooltip__text {
      font-size: @tooltip-font-size;
      line-height: @tooltip-line-height;
    }
  }

  &.ck-balloon-panel_with-arrow {
    &:after,
    &:before {
      display: none;
    }
  }

  .ck-rounded-corners &,
  &.ck-rounded-corners {
    border-radius: @tooltip-border-radius;
  }

  .ck.ck-button:hover {
    background: @_grey_lighten_color; // .uk-button-secondary {}
  }
}

.ck-powered-by-balloon {
  &,
  &.ck.ck-balloon-panel.ck-balloon-panel_visible {
    display: none;
  }
}

// Editor custom wrapper.
.mtx-text-editor-ckeditor5 {
  // .ck class styles.
  .ck.ck-editor__editable_inline {
    padding: 0 @form-padding-x;
  }

  .ck.ck-editor__editable {
    &.ck-focused:not(.ck-editor__nested-editable) {
      border-color: @form-focus-border;
    }
  }

  // .ck-button class styles.
  .ck.ck-button,
  a.ck.ck-button {
    line-height: var(--ck-line-height-base);
  }

  // .ck-toolbar class styles.
  .ck.ck-toolbar {
    padding: 0 @form-padding-x / 2;
  }

  // .ck-dropdown class styles.
  .ck-dropdown__panel {
    --ck-color-text: @dropdown-color;
  }

  .ck.ck-dropdown .ck-dropdown__panel {
    max-height: 20rem;
    overflow-y: auto;
  }

  .ck.ck-dropdown .ck-button.ck-dropdown__button.ck-on {
    // We cannot use aria-expanded as it's true in case button is highlighted,
    // no matter that dropdown isn't shown.
    &:not([data-cke-tooltip-disabled="true"]) {
      border-radius: var(--ck-border-radius);
    }
  }

  // Custom styles.
  .form-textarea-wrapper {
    border: 0 none !important;
  }

  h1, h2, h3, h4, h5, h6,
  .ck[class*=ck-heading_heading] {
    font-weight: unset;
  }

  .ck.ck-heading_heading1 {
    font-size: @base-h1-font-size;
  }

  .ck.ck-heading_heading2 {
    font-size: @base-h2-font-size;
  }

  .ck.ck-heading_heading3 {
    font-size: @base-h3-font-size;
  }

  .ck.ck-heading_heading4 {
    font-size: @base-h4-font-size;
  }

  .ck.ck-heading_heading5 {
    font-size: @base-h5-font-size;
  }

  .ck.ck-heading_heading6 {
    font-size: @base-h6-font-size;
  }
}

 //@TODO: remove this in 11.17.0.
.ck.ck-dialog__content .ck.ck-image-insert-url:after,
.ck.ck-link-form.ck-responsive-form .ck-labeled-field-view:after {
  content: attr(data-helptext);
  padding-top: var(--ck-spacing-small);
  color: var(--ck-color-input-disabled-text);
  font-size: @global-font-size-small;
  display: inline-block;
  text-wrap: pretty;
  max-width: var(--ck-input-width);
}
