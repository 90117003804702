//
// Component: Form advanced
//
// ========================================================================

@form-advanced-size:                          @md-icon-size;
@form-advanced-border:                        transparent;
@form-advanced-border-width:                  0;
@form-advanced-margin-top:                    -.25em;
@form-advanced-color:                         transparent;

@form-advanced-checked-color:                 inherit;

@form-advanced-radio-size:                    @md-icon-size;

@form-advanced-checkbox-font-size:            .75rem;
@form-advanced-checkbox-icon:                 '\e834'; // md-check_box
@form-advanced-checkbox-blank-icon:           '\e835'; // md-check_box_outline_blank
@form-advanced-checkbox-indeterminate-icon:   '\e909'; // md-indeterminate_check_box
@form-advanced-radio-icon:                    '\e837'; // md-radio_button_checked
@form-advanced-radio-blank-icon:              '\e836'; // md-radio_button_unchecked

@form-advanced-disabled-border:               transparent;
@form-advanced-disabled-color:                inherit;


// Component
// ========================================================================

.hook-form-advanced() {}

.hook-form-advanced-checked() {}

.hook-form-advanced-radio() {}

.hook-form-advanced-checkbox() {}

.hook-form-advanced-disabled() {}


// Miscellaneous
// ========================================================================

.hook-form-advanced-misc() {
  .uk-form {
    input[type=checkbox],
    input[type=radio] {
      .material-icon();

      &:before {
        display: block;
        font-family: inherit !important;
        font-size: inherit !important;
        font-weight: 300;
        font-variation-settings: 'wght' 300;
      }
    }

    input[type=checkbox] {
      &:before {
        content: @form-advanced-checkbox-blank-icon; // md-check_box_outline_blank
      }

      &:checked:before {
        content: @form-advanced-checkbox-icon; // md-check_box
        font-variation-settings: 'FILL' 1;
      }

      &:indeterminate:before {
        content: @form-advanced-checkbox-indeterminate-icon; // md-indeterminate_check_box
      }
    }

    input[type=radio] {
      &:before {
        content: @form-advanced-radio-blank-icon; // md-radio_button_unchecked
        font-variation-settings: 'FILL' 0;
      }

      &:checked:before {
        content: @form-advanced-radio-icon; // md-radio_button_checked
        // Reset uikit styles
        background: transparent;
        border: 0 none;
      }
    }
  }
}
