//
// Component: Badge
//
// ========================================================================


// Variables
// ========================================================================

@badge-background:                              @global-primary-background;
@badge-padding-horizontal:                      2px;
@badge-font-size:                               unit(@global-font-size-small, rem);
@badge-line-height:                             1.125rem;
@badge-color:                                   @global-contrast-color;
@badge-hover-color:                             @global-contrast-color;

@badge-notification-font-size:                  @global-font-size * 0.85; // 12px / 14px
@badge-notification-line-height:                @badge-notification-font-size * 1.46; // 18px / 20px

@badge-success-background:                      @global-success-background;
@badge-warning-background:                      @global-warning-background;
@badge-danger-background:                       #ff0000;


// Component
// ========================================================================

.hook-badge() {
  min-width: @badge-line-height; // rem
}


// Color modifier
// ========================================================================

//
// Modifier: `badge-success`
//

.hook-badge-success() {}

//
// Modifier: `badge-warning`
//

.hook-badge-warning() {}

//
// Modifier: `badge-danger`
//

.hook-badge-danger() {}


// Miscellaneous
// ========================================================================

.hook-badge-misc() {
  // Add: `uk-text-badge`
  .uk-text-badge {
    padding: @button-small-padding-vertical @button-small-padding-horizontal;
    border: 1px solid @text_color;
    border-radius: @global-border-radius-circle;
    font-weight: @global-font-weight-normal;
    background-color: transparent;
    color: inherit;
  }

  // Add: `uk-badge-secondary`
  .uk-badge-secondary {
    background-color: @secondary_color;
  }
}
