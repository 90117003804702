@mtx-filter-scrollbar-width: 8px;
@mtx-filter-scrollbar-offset: @mtx-filter-scrollbar-width + 6px;

@mtx-filter-header-add-icon-size: 1.5rem;
@mtx-filter-header-padding-top: 4rem;
@mtx-filter-header-padding-bottom: 2rem;

@mtx-filter-item-padding-x: @global-margin-medium;
@mtx-filter-item-padding-y: @global-margin-small;

@mtx-filter-badge-padding-x: @global-margin;
@mtx-filter-badge-padding-y: @global-margin-small;
@mtx-filter-badge-margin: @global-margin-mini;

@mtx-filter-badge-icon-font-size: 1.25rem; // @close-size - 20px
@mtx-filter-badge-icon-size: (@button-small-padding-horizontal / 2) + @mtx-filter-badge-icon-font-size + @global-margin-small;

// Mtx api filter and search field wrapper
.mtx-filter-and-search-wrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

// Mtx api filter button
.mtx-api-filter {
  -webkit-tap-highlight-color: transparent;

  .uk-button.filter-toggler {
    white-space: nowrap;
  }

  [dir=ltr] & {
    float: right;
    margin-left: @global-margin;
  }

  [dir=rtl] & {
    float: left;
    margin-right: @global-margin;
  }

  @media all and (max-width: @breakpoint-mini-max) {
    .uk-button.filter-toggler {
      padding: calc(@button-padding-vertical - 1px);

      .md-icon {
        width: 100%;
        display: block;
        margin: 0;

        & + span {
          display: none;
        }
      }
    }
  }

  .uk-modal {
    overflow: hidden !important;
    background-color: @_white_color;
    transition: none;
    color: inherit;

    .mtx-dark-theme & {
      background-color: @_black_color;
    }

    @media all and (min-width: @breakpoint-medium) {
      &.blur-backdrop {
        background-color: fade(@_white_color, 80%);
        backdrop-filter: blur(30px);

        .mtx-dark-theme & {
          background-color: fade(@_black_color, 40%);
        }
      }
    }

    .filter-modal {
      background-color: transparent;
    }

    .filter-columns-wrapper {
      position: absolute;
      top: @form-height + @mtx-filter-header-padding-top + @mtx-filter-header-padding-bottom;
      left: 0;
      right: 0;
      bottom: 0;
      overflow-y: auto;
    }
  }
}

.filter-toggler {
  &.uk-button-with-badge .uk-badge.is-small {
    @media all and (max-width: @breakpoint-mini-max) {
      top: 0;
      inset-inline-end: 0;
    }
  }
}

.filter-modal {
  @filter-item-height: calc(@global-font-size * @global-line-height + @mtx-filter-item-padding-y * 2);
  @filter-item-border-radius: calc(@filter-item-height / @button-border-radius-ratio);

  font-size: 1rem;

  .filter-actions {
    padding-top: @mtx-filter-header-padding-top;
    padding-bottom: @mtx-filter-header-padding-bottom;
  }

  .filter-title {
    font-size: 2rem;
    line-height: 1;
    margin: 0;
  }

  .uk-nav {
    margin: 0;
  }

  .uk-nav-header {
    position: relative;
    padding: 0 @mtx-filter-item-padding-x;
    line-height: @base-h1-line-height + 2 * @mtx-filter-item-padding-y;
    text-transform: none;
    margin-bottom: 0;

    .quantity {
      display: none;
      background-color: @global-link-color;
      color: @_white_color;
    }
  }

  .filter-column-search {
    display: none;
    padding: 0 @mtx-filter-item-padding-x @global-margin;
    margin-top: -@mtx-filter-item-padding-y;

    &[aria-hidden='false'] {
      display: block;
    }

    &-toggle {
      padding: 0;
      border: 0 none;
      background: transparent;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      color: @_grey_dark_color;
      opacity: 0;
      transition: @global-hover-transition;

      &[aria-expanded='true'] {
        opacity: 1;
        color: inherit;
      }

      &:hover,
      &:focus {
        color: inherit;
      }

      [dir=ltr] & {
        right: 0;
      }

      [dir=rtl] & {
        left: 0;
      }

      .md-icon {
        font-size: @md-icon-size-small;
      }
    }

    @media all and (max-width: @breakpoint-small-max) {
      &,
      &-toggle {
        display: none !important;
      }
    }
  }

  .filter-column {
    margin-bottom: 2rem;

    ul {
      .reset-list;

      li > ul,
      li + li,
      li.filter-block a + a {
        margin-top: .1875rem;
      }

      ul {
        display: none;
      }
    }

    a {
      display: block;
      padding: @mtx-filter-item-padding-y @mtx-filter-item-padding-x;
      border-radius: @filter-item-border-radius;

      span {
        display: block;
      }

      &.expanded {
        & + .filter-list-toggler .md-icon:after {
          content: '\e5c5'; // md-arrow_drop_down
        }
      }

      &.parent {
        padding-inline-end: @md-icon-size + @mtx-filter-item-padding-x;
      }

      &.has-indent:before {
        content: ' - ';
      }
    }

    .filter-item-wrapper {
      position: relative;
    }

    .filter-expanded-item-wrapper {
      & + ul {
        display: block !important;
      }
    }

    .filter-list-toggler {
      position: absolute;
      top: 0;
      bottom: 0;
      height: 100%;
      width: @md-icon-size + @mtx-filter-item-padding-x;
      padding: 0;
      text-align: center;

      .md-icon {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }

      [dir=ltr] & {
        right: 0;
        border-radius: 0 @filter-item-border-radius @filter-item-border-radius 0;
      }

      [dir=rtl] & {
        left: 0;
        border-radius: @filter-item-border-radius 0 0 @filter-item-border-radius;

        .md-icon:after {
          content: '\e5de'; // md-arrow_left
        }
      }
    }

    .empty {
      padding: @mtx-filter-badge-padding-y @mtx-filter-badge-padding-x;
      color: @global-muted-color;
    }

    &:hover {
      .filter-column-search-toggle {
        opacity: 1;
      }
    }
  }

  @media all and (min-width: @breakpoint-medium) {
    // @todo: check this
    & + .filter-form {
      [dir=ltr] & {
        float: right;
      }

      [dir=rtl] & {
        float: left;
      }
    }

    .filter-columns-wrapper {
      & > div {
        [dir=ltr] & {
          margin-left: -@mtx-filter-item-padding-x;
        }

        [dir=rtl] & {
          margin-right: -@mtx-filter-item-padding-x;
        }
      }
    }

    .os-scrollbar {
      &-track {
        width: @mtx-filter-scrollbar-width;
      }

      &-handle {
        width: @mtx-filter-scrollbar-width - 2px;
        left: 1px;
      }
    }

    .uk-nav-header {
      .quantity {
        display: none;
      }
    }

    // Max-width for columns is changed
    // for pages where 'Activities' filter is present.
    .teams-exposed-forms & { // Calendar
      .filter-column {
        max-width: 320px;
      }
    }

    li.hidden {
      display: none;
    }
  }

  // Mobile styles.
  @media screen and (max-width: @breakpoint-small-max) {
    margin-bottom: @global-margin;

    .mtx-filter-and-search-wrapper & {
      position: static;
      width: auto;
    }

    &,
    & > .uk-button {
      width: 100%;
    }

    .uk-nav-header {
      font-size: 1rem;
      line-height: 2.5;

      .quantity {
        height: 2rem;
        min-width: 2rem;
        border-radius: 2rem;
        margin: -.25rem @global-margin-small 0;
        line-height: 2rem;
        padding: 0 @global-margin-small;
        text-align: center;

        .number {
          font-size: 1rem;
        }
      }

      .icon {
        .material-icon();
        position: absolute;
        top: 50%;
        margin-top: -(@mtx-filter-header-add-icon-size / 2);

        &:after {
          content: '\e5cf'; // md-expand_more
        }

        [dir=ltr] & {
          right: 0;
        }

        [dir=rtl] & {
          left: 0;
        }
      }

      .filter-column-search-toggle {
        display: none;
      }

      & ~ .mtx-custom-scrollbar {
        display: none;
      }

      &.expanded {
        .icon:after {
          content: '\e5ce'; // md-expand_less
        }

        & ~ .mtx-custom-scrollbar {
          display: block;
        }
      }

      [dir=ltr] & {
        padding: 0 @mtx-filter-header-add-icon-size 0 0;
      }

      [dir=rtl] & {
        padding: 0 0 0 @mtx-filter-header-add-icon-size;
      }
    }

    .filter-column {
      float: none;
      width: 100%;
      margin-bottom: 1rem;

      ul.filter-list {
        [dir=ltr] & {
          margin-right: @mtx-filter-header-add-icon-size;

          &.filter-multilevel-list {
            margin-right: 0;
          }
        }

        [dir=rtl] & {
          margin-left: @mtx-filter-header-add-icon-size;

          &.filter-multilevel-list {
            margin-left: 0;
          }
        }
      }
    }
  }
}

// Mtx api filter badges
.filter-badges {
  .clearfix;
  position: relative;
  margin-bottom: @global-margin;
  clear: both;

  .filters-wrapper {
    .reset-list;

    .uk-badge {
      position: relative;
      max-width: 100%;
      border-radius: @button-border-radius-small;
      font-size: @button-small-font-size;
      font-weight: @global-font-weight-normal;
      line-height: @button-small-line-height;
      min-height: @button-small-height;

      &:only-child {
        margin: 0 0 @mtx-filter-badge-margin;
      }

      .uk-close {
        box-shadow: none;
        height: @mtx-filter-badge-icon-font-size;
      }

      &:focus-within {
        .focus-state-box-shadow();
      }
    }

    .uk-badge {
      text-align: initial;

      .badge-text {
        word-break: break-word;

        @media (min-width: @breakpoint-small) {
          .text-ellipsis();
          max-width: calc(35ch - @mtx-filter-badge-icon-size);
        }
      }
    }

    .uk-close {
      position: absolute;
      top: @mtx-filter-badge-padding-y;
      opacity: .5; // .uk-close - 0.3; it was barely visible

      &:after {
        width: @mtx-filter-badge-icon-font-size;
        font-size: @mtx-filter-badge-icon-font-size;
        line-height: @mtx-filter-badge-icon-font-size;
        vertical-align: initial;
      }

      &:hover,
      &:focus {
        opacity: 1;
      }
    }
  }

  .filters-reset {
    display: block;
    border-radius: @button-border-radius-small;
    border: 1px solid @global-border-color; // .hook-button() {}
    padding: calc(@button-small-padding-vertical - 1px) calc(@button-small-padding-horizontal - 1px);
    font-size: @button-small-font-size;
    font-weight: @global-font-weight-normal;
    line-height: @button-small-line-height;
    min-height: @button-small-height;
    background-color: transparent;
    color: inherit;
    min-width: fit-content;
    text-decoration: none;

    & > div {
      white-space: nowrap;
    }
  }

  [dir=ltr] & {
    .filters-wrapper {
      margin-right: @global-margin;

      .uk-badge {
        float: left;
        margin: 0 @mtx-filter-badge-margin @mtx-filter-badge-margin 0;
        padding: @button-small-padding-vertical @mtx-filter-badge-icon-size @button-small-padding-vertical @button-small-padding-horizontal;
      }

      .uk-close {
        right: @button-small-padding-horizontal / 2;
      }
    }
  }

  [dir=rtl] & {
    .filters-wrapper {
      margin-left: @global-margin;

      .uk-badge {
        float: right;
        margin: 0 0 @mtx-filter-badge-margin @mtx-filter-badge-margin;
        padding: @button-small-padding-vertical @button-small-padding-horizontal @button-small-padding-vertical @mtx-filter-badge-icon-size;
      }

      .uk-close {
        left: @button-small-padding-horizontal / 2;
      }
    }
  }
}

// Mtx api filter active mark
.filter-active-mark {
  @size: .75rem;

  .circle(@size);

  @media all and (min-width: @breakpoint-small) {
    top: -(@size / 3);

    [dir=ltr] & {
      right: -(@size / 3);
    }

    [dir=rtl] & {
      left: -(@size / 3);
    }
  }
}

// Filter form
.filter-form-shrinked-filters-view {
  & > div {
    display: flex;
    align-items: start;
    justify-content: flex-end;
  }

  .mtx-api-filter {
    margin: 0 !important;
  }

  .form-item-search {
    margin: 0 @global-margin-mini;
  }

  @media all and (max-width: @breakpoint-medium-max) {
    margin-bottom: @global-margin-small;

    & > div {
      flex-wrap: wrap;
    }
  }
}
